import React, { useRef, useState } from "react";
import SignUp from "./Signup/SignUp";
import Login from "./Login/Login";
import PhoneLogin from "./Login/PhoneLogin";
import EmailLogin from "./Login/EmailLogin";
import { RxCross1 } from "react-icons/rx";
import { MdArrowBackIosNew } from "react-icons/md";
import EmailPhone from "./Signup/EmailPhone";
import UsernameAndPassword from "./Signup/UsernameAndPassword";
import Username from "./Signup/Username";
import ResetPassword from "./ResetPassword/ResetPassword";
import Birthday from "./Signup/Birthday";

const AuthContainer = ({ show, onHide }) => {
  const modalRef = useRef();
  const [screen, setScreen] = useState("login");
  const [resetScreen, setResetScreen] = useState("");
  const [authEmail, setAuthEmail] = useState("");

  const handleDOB = (email) => {
    setAuthEmail(email);
    setScreen("dob");
  };

  const handleSignup = () => {
    setScreen("signup");
  };
  const handleLogin = () => {
    setScreen("login");
  };
  const handlePhone = () => {
    setScreen("phoneLogin");
  };
  const resetPassword = () => {
    setScreen("forgot");
  };
  const handleEmail = () => {
    setScreen("emailLogin");
  };
  // const signupPhone = () => {
  //   setSignup(false);
  //   setLogin(false);
  //   setPhoneLogin(false);
  //   setEmailLogin(false);
  //   setEmailphone(false);
  // };
  const emailPhone = () => {
    setScreen("phoneSignup");
  };

  const hideModal = () => {
    modalRef.current.classList.add("removeModal");
    setTimeout(() => {
      onHide();
    }, 400);
  };
  const goBack = () => {
    switch (screen) {
      case "signup":
        setScreen("login");
        return;
      case "phoneLogin":
        setScreen("login");
        return;
      case "emailLogin":
        setScreen("phoneLogin");
        return;
      case "phoneSignup":
        setScreen("signup");
        return;
      case "dob":
        setScreen("login");
        return;
      case "forgot":
        if (resetScreen === "email") {
          setScreen("emailLogin");
        } else {
          setScreen("phoneLogin");
        }
        return;
      default:
        setScreen("login");
        return;
    }
  };

  return (
    <div className="auth_container">
      <div className="modal_container">
        <div
          ref={modalRef}
          className="main_modal p-4"
          style={{ borderRadius: ".7rem" }}
        >
          {/* ....login..... */}
          <div className="d-flex justify-content-between">
            <div>
              {[
                "signup",
                "phoneLogin",
                "emailLogin",
                "phoneSignup",
                "forgot",
                "dob",
              ].includes(screen) && (
                <div className="crossIcon" onClick={goBack}>
                  <MdArrowBackIosNew />
                </div>
              )}
            </div>
            <div className="crossIcon" onClick={hideModal}>
              <RxCross1 size={21} />
            </div>
          </div>
          {screen === "login" && (
            <Login
              handleSignup={handleSignup}
              handlePhone={handlePhone}
              handleDob={handleDOB}
              onHide={onHide}
            />
          )}
          {screen === "signup" && (
            <SignUp
              onHide={onHide}
              handleLogin={handleLogin}
              handleDob={handleDOB}
              emailPhone={emailPhone}
            />
          )}
          {screen === "phoneLogin" && (
            <PhoneLogin
              onHide={onHide}
              handleSignup={handleSignup}
              handleEmail={handleEmail}
              resetPassword={resetPassword}
              resetScreen={setResetScreen}
            />
          )}

          {screen === "emailLogin" && (
            <EmailLogin
              onHide={onHide}
              handlePhone={handlePhone}
              handleSignup={handleSignup}
              resetPassword={resetPassword}
              resetScreen={setResetScreen}
            />
          )}
          {screen === "phoneSignup" && (
            <EmailPhone setScreen={setScreen} handleLogin={handleLogin} />
          )}
          {screen === "usernamePassword" && (
            <UsernameAndPassword onHide={onHide} />
          )}
          {screen === "username" && <Username onHide={onHide} />}
          {screen === "forgot" && (
            <ResetPassword
              resetScreen={resetScreen}
              setResetScreen={setResetScreen}
              handleSignup={handleSignup}
              onHide={onHide}
            />
          )}
          {screen === "dob" && (
            <Birthday
              setScreen={setScreen}
              handleLogin={handleLogin}
              email={authEmail}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
