import { login, logout, updateUsername } from "../redux/slices/authSlice";
import { baseQuery } from "./baseQuery";

export const authApi = baseQuery.injectEndpoints({
  endpoints: (builder) => ({
    sendOTP: builder.mutation({
      query: (body) => ({
        url: "/auth/sendOTP",
        method: "POST",
        body,
      }),
    }),
    sendMailOTP: builder.mutation({
      query: (body) => ({
        url: "/auth/sendMailOTP",
        method: "POST",
        body,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (body) => ({
        url: "/auth/verify-phone",
        method: "POST",
        body,
      }),
    }),
    loginWithPhone: builder.mutation({
      query: (credentials) => ({
        url: "/auth/loginWithPhone",
        method: "POST",
        body: credentials,
      }),

      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
    loginWithPhonePassword: builder.mutation({
      query: (credentials) => ({
        url: "/auth/loginWithPhoneAndPassword",
        method: "POST",
        body: credentials,
      }),

      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
    loginWithEmail: builder.mutation({
      query: (credentials) => ({
        url: "/auth/loginWithEmail",
        method: "POST",
        body: credentials,
      }),

      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
    signupWithMobile: builder.mutation({
      query: (credentials) => ({
        url: "/auth/registerWithMobile",
        method: "POST",
        body: credentials,
      }),

      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
    signupWithMail: builder.mutation({
      query: (credentials) => ({
        url: "/auth/registerWithEmail",
        method: "POST",
        body: credentials,
      }),

      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
    logout: builder.mutation({
      query: (token) => ({
        url: "/auth/logout",
        method: "POST",
        body: { refreshToken: token },
      }),

      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        dispatch(logout());
      },
    }),
    updateUserInfo: builder.mutation({
      query: (body) => ({
        url: `/user/updateUser/${body.id}`,
        method: "PATCH",
        body: body.data,
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.username) {
            dispatch(updateUsername(data.username));
          }
        } catch (err) {}
      },
    }),
    resetWithPhone: builder.mutation({
      query: (body) => ({
        url: "/auth/resetWithPhone",
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
    resetWithEmail: builder.mutation({
      query: (body) => ({
        url: "/auth/resetWithEmail",
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
    authGoogle: builder.mutation({
      query: (body) => ({
        url: "/auth/google",
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.success) {
            dispatch(login(data));
          }
        } catch (err) {}
      },
    }),
    authFb: builder.mutation({
      query: (body) => ({
        url: "/auth/fb",
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.success) {
            dispatch(login(data));
          }
        } catch (err) {}
      },
    }),
    registerWithGoogle: builder.mutation({
      query: (body) => ({
        url: "/auth/registerWithGoogle",
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (err) {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginWithPhoneMutation,
  useLoginWithPhonePasswordMutation,
  useLogoutMutation,
  useSignupWithMobileMutation,
  useSendOTPMutation,
  useVerifyOTPMutation,
  useUpdateUserInfoMutation,
  useSendMailOTPMutation,
  useSignupWithMailMutation,
  useLoginWithEmailMutation,
  useResetWithEmailMutation,
  useResetWithPhoneMutation,
  useAuthGoogleMutation,
  useRegisterWithGoogleMutation,
  useAuthFbMutation
} = authApi;
