import { MutatingDots } from "react-loader-spinner";
import { useSelector } from "react-redux";

const Loader = () => {
  const { isLoading } = useSelector((state) => state.global);
  if (isLoading) {
    return (
      <div className="loader">
        <MutatingDots
          height="100"
          width="100"
          color="#ee353b"
          secondaryColor="aquamarine"
          radius="12.5"
          visible={isLoading}
          ariaLabel="loading"
          // wrapperStyle
          // wrapperClass
        />
      </div>
    );
  } else {
    return null;
  }
};

export default Loader;
