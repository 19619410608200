import React from "react";
import logo from "../assets/img/logo.png";
import kids from "../assets/img/kids.png";

const ComingSoon = () => {
  return (
    <div className="coming_soon">
      <div
        style={{ width: "34rem", marginRight: "6rem" }}
        className="p-2 d-flex text-center align-items-center flex-column"
      >
        <img src={logo} alt="" style={{ width: "9.6rem" }} />
        <div className="my-3">
          <h1 className="cmS_heading mb-0 ">
            <span className="mx-2 fw-bold" style={{ color: "#ec1c24" }}>
              COMING
            </span>
            SOON
          </h1>
          <p
            className="mt-0 comingsoon_subheading"
            style={{ letterSpacing: "5px" }}
          >
            Where Creativity Meets Safety!
          </p>
        </div>
        <div>
          <p style={{ fontSize: "14px" }} className="comingsoon_desc">
            Get ready for a whole new world of creativity, connection, and
            positivity for kids. Instant Awesome Kids is launching an exciting
            new website and mobile app. Join us on this incredible journey to
            create a safe, supportive, and worry-free online community. Sign up
            for updates to be the first to know when we go live. Let's make the
            internet a safer and more inspiring place for kids. Get ready to
            experience Instant Awesome Kids!
          </p>
        </div>
        <div className="input_container d-flex justify-content-center">
          <input
            className="p-2 my-1"
            style={{ width: "18rem" }}
            type="text"
            placeholder="Enter Email"
          />
          <button className="btn text-light bg-primary ms-4 py-2 my-1">
            Notify Me
          </button>
        </div>
        <div className="mobile_img">
          <img src={kids} alt="" />
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ComingSoon;
