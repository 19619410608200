import { useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateUserInfoMutation } from "../../../services/authService";

const Username = ({ onHide }) => {
  const [updateUser] = useUpdateUserInfoMutation();
  const [isLoading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authData);
  const [username, setUsername] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateUser({ id: user.id, data: { username } }).then((res) => {
      setLoading(false);
      if (!res.error) {
        onHide();
      }
    });
  };

  return (
    <div>
      <div className="phoneLogin">
        <div className="px-5" style={{ width: "100%" }}>
          <form
            onSubmit={handleSubmit}
            className=" login_form d-flex flex-column gap-2"
          >
            <div class="input_container input-group input-group-lg">
              <input
                className="input_container"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <button disabled={username === ""} className="code my-3 py-2">
              {isLoading ? (
                <div class="loadingio-spinner-rolling-x0a55a0iw5">
                  <div class="ldio-6g08kqzye5a">
                    <div></div>
                  </div>
                </div>
              ) : (
                "Signup"
              )}
            </button>
            <span onClick={onHide} className="text-center cursor-pointer">
              Skip
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Username;
