import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from "./slices/authSlice";
import global from "./slices/globalSlice";
import { baseQuery } from "../services/baseQuery";
import { rtkQueryErrorLogger } from "../utils/errorHandling";
import { rtkQueryLoader } from "../utils/handleLoading";
import { rtkQuerySuccess } from "../utils/handleSuccess";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  [baseQuery.reducerPath]: baseQuery.reducer,
  authData: auth,
  global,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      baseQuery.middleware,
      rtkQueryErrorLogger,
      rtkQueryLoader,
      rtkQuerySuccess,
      thunk
    ),
});
setupListeners(store.dispatch);

export const persistor = persistStore(store);

export default store;
