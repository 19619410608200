import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { AiFillHeart } from "react-icons/ai";
import { FaCommentDots } from "react-icons/fa";
import { BsBookmarkFill } from "react-icons/bs";
import { BiCodeAlt } from "react-icons/bi";
import { RiSendPlaneFill } from "react-icons/ri";
import { FaWhatsapp, FaFacebookF, FaTwitter } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../redux/slices/globalSlice";
import {
  useLikeVideoMutation,
  useFollowUserMutation,
  useGetCommentsQuery,
  useAddCommentMutation,
  useDeleteVideoMutation,
} from "../services/videoService";
import CommentBox from "./CommentBox";
import moment from "moment";

const FullVideo = ({
  video,
  isMuted,
  setIsMuted,
  onHide,
  setIndex,
  index,
  length,
  setShouldRefetch,
  allowDelete,
}) => {
  const commentRef = useRef();
  const { data } = useGetCommentsQuery(video.id, {
    refetchOnMountOrArgChange: true,
  });
  const [comments, setComments] = useState([]);
  const [addComment] = useAddCommentMutation();
  const [deleteVideo] = useDeleteVideoMutation();
  const [likeVideo] = useLikeVideoMutation();
  const [followerUser] = useFollowUserMutation();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.authData);
  const playerRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isLiked, setIsLiked] = useState(video.liked);
  const [isFollowed, setIsFollowed] = useState(video.followed);
  const [likes, setLikes] = useState(video.likes);
  const [newComments, setNewComments] = useState([]);

  const handleLike = () => {
    if (!isAuthenticated) {
      dispatch(setModal(true));
    } else {
      likeVideo({ video: video.id }).then((res) => {
        if (!res.error) {
          setIsLiked(!isLiked);
          setLikes(isLiked ? likes - 1 : likes + 1);
        }
      });
    }
  };

  const handleFollower = () => {
    if (!isAuthenticated) {
      dispatch(setModal(true));
    } else {
      followerUser({ user: video.user.id }).then((res) => {
        if (!res.error) {
          setIsFollowed(!isFollowed);
        }
      });
    }
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    playerRef.current?.load();
  }, [video]);

  const emojis = [
    "👍",
    "🔥",
    "😄",
    "😁",
    "🙂",
    "😃",
    "😅",
    "😂",
    "🤣",
    "😇",
    "😊",
    "🥰",
    "😍",
    "🤗",
    "😮",
    "⭐",
    "💕",
  ];

  useEffect(() => {
    if (data) {
      setComments(data);
    }
  }, [comments]);

  const addNewComment = (comment) => {
    addComment({ comment, video: video.id }).then((res) => {
      if (!res.error) {
        setNewComments([
          ...newComments,
          { video: video.id, user, comment, createdAt: moment() },
        ]);
        scrollToBottom();
      }
    });
  };

  const scrollToBottom = () => {
    commentRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [newComments, comments]);

  const handleDeleteVideo = () => {
    deleteVideo(video.id).then((res) => {
      if (!res.error && res.data.success) {
        setShouldRefetch(true);
        onHide();
      }
    });
  };

  return (
    <div className="d-flex full-video-container">
      <div className="col-7 full-video-box">
        <video
          ref={playerRef}
          loop
          playsInline
          autoPlay
          muted={isMuted}
          onLoadedData={() => setIsLoading(false)}
          onWaiting={() => setIsLoading(true)}
          onPlaying={() => setIsLoading(false)}
        >
          <source
            id="video-src"
            src={
              "https://iak-media.nyc3.digitaloceanspaces.com/" +
              video.video +
              `?timestamp=${Date.now()}`
            }
            type="video/mp4"
          />
        </video>
        <div onClick={onHide} className="box-close video-box-controls">
          <AiOutlineClose size={24} />
        </div>
        <div className="box-menu video-box-controls">
          <BsThreeDots size={24} />
        </div>
        <div className="controller-icons">
          {index !== 0 && (
            <div
              onClick={() => setIndex((prev) => prev - 1)}
              className="video-box-controls"
            >
              <IoIosArrowUp size={24} />
            </div>
          )}
          {index < length - 1 && (
            <div
              onClick={() => setIndex((prev) => prev + 1)}
              className="video-box-controls"
            >
              <IoIosArrowDown size={24} />
            </div>
          )}
        </div>
        <div className="volume-icon video-box-controls">
          {isMuted ? (
            <HiOutlineVolumeOff
              onClick={handleMute}
              className="cursor-pointer"
              size={24}
            />
          ) : (
            <HiOutlineVolumeUp
              onClick={handleMute}
              className="cursor-pointer"
              size={24}
            />
          )}
        </div>
      </div>
      <div className="col-5 video-info-box">
        <div className="video-user-info">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex mb-3 gap-3 align-items-center">
              <div>
                <img
                  className="avatar video-card-avatar"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                  crossOrigin="anonymous"
                  alt=""
                />
              </div>
              <div className="profile_details">
                <p className="d-flex align-items-center m-0">
                  <span className="fw-bold me-3">{video.user?.username}</span>
                </p>
              </div>
            </div>
            {video.user.id !== user?.id ? (
              <button
                onClick={handleFollower}
                className={`btn py-2 px-4 ${
                  isFollowed ? "btn-outline-primary text-bold" : "btn-primary"
                }`}
              >
                {isFollowed ? "Following" : "Follow"}
              </button>
            ) : (
              allowDelete && (
                <button
                  onClick={handleDeleteVideo}
                  className="btn btn-outline-primary"
                >
                  Delete
                </button>
              )
            )}
          </div>
          <span className="cardSpan font-weight-light">{video.caption}</span>
        </div>
        <div className="ms-4 d-flex align-items-center">
          <div className="d-flex align-items-center" onClick={handleLike}>
            <div className={`video-profile-controls ${isLiked && "active"}`}>
              <AiFillHeart size={22} />
            </div>
            <span
              className="ms-2"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              {likes}
            </span>
          </div>
          <div className="video-profile-controls ms-3">
            <FaCommentDots size={20} />
          </div>
          <span
            className="ms-2"
            style={{ fontWeight: "500", fontSize: "14px" }}
          >
            589
          </span>
          <div className="video-profile-controls ms-3">
            <BsBookmarkFill size={20} />
          </div>
          <span
            className="ms-2"
            style={{ fontWeight: "500", fontSize: "14px" }}
          >
            9.5k
          </span>

          <div className="video-profile-controlsMedia bg-secondary text-light ms-4">
            <BiCodeAlt size={18} />
          </div>
          <div className="video-profile-controlsMedia bg-primary text-light ms-2">
            <RiSendPlaneFill size={18} />
          </div>
          <div
            className="video-profile-controlsMedia text-light ms-2"
            style={{ background: "#59CE72" }}
          >
            <FaWhatsapp size={17} />
          </div>
          <div
            className="video-profile-controlsMedia text-light ms-2"
            style={{ background: "#1877F2" }}
          >
            <FaFacebookF size={17} />
          </div>
          <div className="video-profile-controlsMedia bg-info text-light ms-2">
            <FaTwitter size={17} />
          </div>
          <IoIosShareAlt size={22} className="ms-2" />
        </div>
        <div
          className="video-user-info d-flex justify-content-between align-items-center"
          style={{ padding: "0px 18px", border: "1px solid #cccccc" }}
        >
          {/* <span className="cardSpan font-weight-light">{`${window.location.origin}/${video?.user?.id}/${video.id}`}</span> */}
          <span className="cardSpan font-weight-light">
            Video Link Here...
          </span>
          <button className="copylinkProfile">Copy Link</button>
        </div>
        <hr className="m-0 mt-1" />
        <div className="scroll_comments">
          {[...comments, ...newComments]?.map((comment, i) => (
            <CommentBox key={i} comment={comment} />
          ))}
          <div ref={commentRef}></div>
        </div>
        <div className="d-flex emojis-input">
          {emojis.map((emoji, i) => (
            <span onClick={() => addNewComment(emoji)} key={i}>
              {emoji}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FullVideo;
