import { useState } from "react";
import { RiEyeCloseLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useUpdateUserInfoMutation } from "../../../services/authService";

const UsernameAndPassword = ({ onHide }) => {
  const [updateUser] = useUpdateUserInfoMutation();
  const [isLoading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authData);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({ username: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateUser({ id: user.id, data }).then((res) => {
      setLoading(false);
      if (!res.error) {
        onHide();
      }
    });
  };

  return (
    <div>
      <div className="phoneLogin">
        <div className="px-5" style={{ width: "100%" }}>
          <form
            onSubmit={handleSubmit}
            className=" login_form d-flex flex-column gap-2"
          >
            <div class="input_container input-group input-group-lg">
              <input
                className="input_container"
                type="text"
                placeholder="Username"
                value={data.username}
                name="username"
                onChange={handleChange}
              />
            </div>
            <div className="input_container input-group input-group-lg">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={data.password}
                name="password"
                onChange={handleChange}
              />
              <RiEyeCloseLine
                className="close_eye"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <button
              disabled={data.username === "" && data.password === ""}
              className="code my-3 py-2"
            >
              {isLoading ? (
                <div class="loadingio-spinner-rolling-x0a55a0iw5">
                  <div class="ldio-6g08kqzye5a">
                    <div></div>
                  </div>
                </div>
              ) : (
                "Signup"
              )}
            </button>
            <span onClick={onHide} className="text-center cursor-pointer">
              Skip
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UsernameAndPassword;
