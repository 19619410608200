import React, { useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { BsFillHeartFill } from "react-icons/bs";
import { FaCommentDots } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import coin from "../assets/img/coin.png";
import { MutatingDots } from "react-loader-spinner";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
const MobileVideoCard = ({
  video,
  index,
  currentVisibleIndex,
  setCurrentVisibleIndex,
  isMuted,
  setIsMuted,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  const playerRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);

  useEffect(() => {
    setIsVisible(inView);
    if (inView) {
      setCurrentVisibleIndex(index);
    }
  }, [inView]);

  const handleClick = (e) => {
    if (!isLoading) {
      if (isPlaying) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (isVisible && !isPlaying && index === currentVisibleIndex) {
      playerRef.current.currentTime = 0;
      playerRef.current.play().then(() => {
        setIsPlaying(true);
      });
    } else if (isPlaying && index !== currentVisibleIndex) {
      playerRef.current.pause();
      setIsPlaying(false);
    }
  }, [isVisible, index, currentVisibleIndex, shouldPlay]);

  return (
    <div
      style={{ marginTop: "0 !important", maxWidth: "100vw" }}
      className="p-4 main_video m-auto  d-flex justify-content-between "
    >
      <div className="left m-xl-5">
        <div className="d-flex mb-3 gap-3 align-items-center">
          <div className="profile-img">
            <img src={video?.user?.profile} crossOrigin="anonymous" alt="" />
          </div>
          <div className="d-flex flex-wrap profile_details">
            <p className="d-flex align-items-center m-0">
              <span className="fw-bold me-3">{video?.user?.username}</span>
            </p>
          </div>
          <div className="d-flex justify-content-end w-100">
            <button className="btn follow  btn-primary py-2  px-4">
              Follow
            </button>
          </div>
        </div>

        <div className="mb-2">
          <span className="cardSpan">{video.caption}</span>
        </div>
        <div className="video-player">
          <div className="video-loader" ref={ref}>
            <MutatingDots
              height="100"
              width="100"
              color="#ee353b"
              secondaryColor="aquamarine"
              radius="12.5"
              visible={isLoading}
              ariaLabel="loading"
              // wrapperStyle
              // wrapperClass
            />
          </div>
          <video
            onClick={handleClick}
            ref={playerRef}
            loop
            playsInline
            muted={isMuted}
            onLoadedData={() => setIsLoading(false)}
            onWaiting={() => setIsLoading(true)}
            onPlaying={() => setIsLoading(false)}
          >
            <source
              src={
                "https://iak-media.nyc3.digitaloceanspaces.com/" + video.video
              }
              type="video/mp4"
            />
          </video>
          <div className="d-flex justify-content-between video-controls align-items-center visible">
            {isPlaying ? (
              <FaPause
                onClick={handleClick}
                size={20}
                className="cursor-pointer"
              />
            ) : (
              <FaPlay
                onClick={handleClick}
                size={20}
                className="cursor-pointer"
              />
            )}
            {isMuted ? (
              <HiOutlineVolumeOff
                onClick={handleMute}
                className="cursor-pointer"
                size={25}
              />
            ) : (
              <HiOutlineVolumeUp
                onClick={handleMute}
                className="cursor-pointer"
                size={25}
              />
            )}
          </div>
        </div>
      </div>

      {/* <div className="right mt-5">
              <div className="mb-4">
               
              </div>
            </div> */}
    </div>
  );
};

export default MobileVideoCard;
