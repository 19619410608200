import { baseQuery } from "./baseQuery";

export const videoApi = baseQuery.injectEndpoints({
  endpoints: (builder) => ({
    addVideo: builder.mutation({
      query: (body) => ({
        url: "/video",
        method: "POST",
        body,
      }),
    }),
    getVideos: builder.query({
      query: (data) => `/video?page=${data.currentPage}&user=${data.user}`,
    }),
    getComments: builder.query({
      query: (video) => `/video/getComments/${video}`,
    }),
    addComment: builder.mutation({
      query: (body) => ({
        url: "/video/addComment",
        method: "POST",
        body,
      }),
    }),
    likeVideo: builder.mutation({
      query: (body) => ({
        url: "/video/likeVideo",
        method: "POST",
        body,
      }),
    }),
    deleteVideo: builder.mutation({
      query: (id) => ({
        url: `/video/video/${id}`,
        method: "DELETE",
      }),
    }),
    getVideo: builder.query({
      query: (data) => `video/video/${data.id}?user=${data.user}`,
    }),
    followUser: builder.mutation({
      query: (body) => ({
        url: "/video/addFollower",
        method: "POST",
        body,
      }),
    }),
    shareVideo: builder.mutation({
      query: (id) => ({
        url: `/video/video/${id}`,
        method: "PATCH",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddVideoMutation,
  useGetVideosQuery,
  useLikeVideoMutation,
  useFollowUserMutation,
  useGetCommentsQuery,
  useAddCommentMutation,
  useDeleteVideoMutation,
  useGetVideoQuery,
  useShareVideoMutation,
} = videoApi;
