import React, { useState } from "react";
import { RiEyeCloseLine } from "react-icons/ri";
import {
  useSendMailOTPMutation,
  useSignupWithMailMutation,
} from "../../../services/authService";
import { toast } from "react-toastify";

const SignupEmail = ({ signupPhone, dob, setUsername }) => {
  const [register] = useSignupWithMailMutation();
  const [sendOTP] = useSendMailOTPMutation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOTP] = useState("");
  const [sent, setSent] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [sending, setIsSending] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const startTimer = (t) => {
    setSeconds(t);
    let s = t;
    var interval = setInterval(() => {
      s -= 1;
      setSeconds(s);
      if (s <= 0) {
        setSent(false);
        setIsDisabled(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleOTP = (e) => {
    e.preventDefault();
    if (sending) {
      return;
    }
    setIsSending(true);
    sendOTP({ email: data.email, usingPhone: false }).then((res) => {
      setIsSending(false);
      if (!res.error) {
        setSent(true);
        setIsDisabled(true);
        startTimer(60);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const changeOTP = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setOTP(numericValue.length < 7 ? numericValue : otp);
  };

  const validateEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dob.month === 0 || dob.day === 0 || dob.year === 0) {
      return toast.error("Invalid date of birth");
    }
    setLoading(true);
    register({
      ...data,
      dob: `${dob.day}/${dob.month}/${dob.year}`,
      usingPhone: false,
      otp,
    }).then((res) => {
      setLoading(false);
      if (!res.error) {
        setUsername();
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <label style={{ fontSize: "14px", fontWeight: "500" }}>Email</label>
        <span onClick={signupPhone} className="phone_login_text cursor-pointer">
          Signup with phone
        </span>
      </div>

      <form
        onSubmit={handleSubmit}
        className="login_form d-flex flex-column gap-2"
      >
        <div className="input_container input-group input-group-lg">
          <input
            type="email"
            value={data.email}
            onChange={handleChange}
            name="email"
            placeholder="Email"
          />
        </div>
        <div className="input_container input-group input-group-lg">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={data.password}
            name="password"
            onChange={handleChange}
          />
          <RiEyeCloseLine
            className="close_eye"
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
        <div class="input_container input-group input-group-lg">
          <input
            type="text"
            className=" form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            placeholder="Enter 6-digit code"
            value={otp}
            onChange={changeOTP}
          />
          <button
            onClick={handleOTP}
            className={`code input-group-text`}
            id="inputGroup-sizing-lg"
            disabled={isDisabled || data.email === "" || data.password === ""}
          >
            {sending ? (
              <div class="loadingio-spinner-rolling-x0a55a0iw5">
                <div class="ldio-6g08kqzye5a">
                  <div></div>
                </div>
              </div>
            ) : sent ? (
              `Resend in ${seconds}s`
            ) : (
              "Send code"
            )}
          </button>
        </div>

        <button
          disabled={
            otp.length !== 6 ||
            isLoading ||
            data.password === "" ||
            data.email === "" ||
            !validateEmail(data.email)
          }
          className="code mt-3 py-2 mb-5"
        >
          {isLoading ? (
            <div class="loadingio-spinner-rolling-x0a55a0iw5">
              <div class="ldio-6g08kqzye5a">
                <div></div>
              </div>
            </div>
          ) : (
            "Next"
          )}
        </button>
      </form>
    </>
  );
};

export default SignupEmail;
