import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import AuthContainer from "./auth/AuthContainer";
import { MdOutlineMoreVert } from "react-icons/md";
import { BsSend } from "react-icons/bs";
import { BiMessageMinus } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { BiBookmark } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { TbMessageLanguage } from "react-icons/tb";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { CiKeyboard } from "react-icons/ci";
import { BiMoon } from "react-icons/bi";
import { BsBoxArrowInRight } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../services/authService";
import { setModal } from "../redux/slices/globalSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const [logout] = useLogoutMutation();
  const { isAuthenticated, user, tokens } = useSelector(
    (state) => state.authData
  );
  const { isModalActive } = useSelector((state) => state.global);
  const [isNavActive, setIsNavActive] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const handleMenuClick = () => {
    setIsNavActive(!isNavActive);
  };

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get("query");
    if (queryParam) {
      setSearchQuery(queryParam);
    }
  }, [location.search]);

  const handleLogout = () => {
    logout(tokens.refresh.token).then((res) => {
      setDropdown(false);
      history.push("/home");
    });
  };

  const handleSearchIconClick = () => {
    if (searchQuery) {
      history.push(`/search?query=${searchQuery}`);
    }
  };
  return (
    <>
      {isModalActive && (
        <AuthContainer
          show={isModalActive}
          onHide={() => dispatch(setModal(false))}
        />
      )}
      <nav className="navbar navbar-expand-lg navbar-light bg-light main_nav">
        <div className="container-fluid d-flex justify-content-between">
          <h5 className="navbar-brand m-0">
            Hello, Let's Be{" "}
            <span className="fw-bold" style={{ color: "#ec1c24" }}>
              Awesome
            </span>
          </h5>

          <div className="collapse navbar-collapse flex-grow-0 w-100 ms-2">
            <div className="d-flex search_input align-items-center w-100">
              <input
                type="text"
                placeholder="Search videos, username..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchIconClick();
                  }
                }}
              />
              <span className="line mx-2"></span>
              <AiOutlineSearch
                className="cursor-pointer"
                style={{ color: "rgba(0,0,0,0.3)" }}
                size={30}
                onClick={handleSearchIconClick}
              />
            </div>
            <span className="line mx-3"></span>
            <div className="d-flex gap-2 align-items-center">
              <Link to="/upload">
                <button className="btn fw-bold py-2 btn-outline-primary nav_btn">
                  + Create
                </button>
              </Link>

              {!isAuthenticated ? (
                <button
                  className="btn btn-primary py-2 nav_btn"
                  onClick={() => dispatch(setModal(true))}
                >
                  Log in
                </button>
              ) : (
                <>
                  <BsSend size={25} className="mx-2" />
                  {/* <BiMessageMinus size={30} /> */}
                  <img
                    onClick={() => setDropdown(!dropdown)}
                    src={user?.profile}
                    alt=""
                    className="mx-2 cursor-pointer avatar"
                  />
                  <div
                    className={`profile_hidden  shadow rounded bg-white ${
                      dropdown ? "profile_display" : ""
                    }`}
                  >
                    <Link
                      to={"/profile"}
                      className="text-decoration-none text-dark"
                    >
                      <div className="profile_dropdown_inner py-2 ps-3 pe-4">
                        <AiOutlineUser size={21} />
                        <p className="mb-0 ms-2">Profile</p>
                      </div>
                    </Link>

                    <div className="profile_dropdown_inner py-2 ps-3 pe-4">
                      <BiBookmark size={21} />
                      <p className="mb-0 ms-2">Favorites</p>
                    </div>

                    <div className="profile_dropdown_inner py-2 ps-3 pe-4">
                      <FiSettings size={21} />
                      <p className="mb-0 ms-2">Settings</p>
                    </div>

                    <div className="profile_dropdown_inner py-2 ps-3 pe-4">
                      <AiOutlineQuestionCircle size={21} />
                      <p className="mb-0 ms-2">Feedback and help</p>
                    </div>

                    <div className="profile_dropdown_inner py-2 ps-3 pe-4">
                      <BiMoon size={21} />
                      <p className="mb-0 ms-2">Dark mode</p>
                    </div>
                    <hr className="m-0" />

                    <div
                      onClick={handleLogout}
                      className="profile_dropdown_inner py-2 ps-3 pe-4"
                    >
                      <BsBoxArrowInRight size={21} />
                      <p className="mb-0 ms-2">Log out</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <span className="line mx-3"></span>
            <MdOutlineMoreVert
              style={{ width: "50px" }}
              size={25}
              className="cursor-pointer"
            />
          </div>
        </div>
      </nav>

      {/* responsive navbar  */}
      <div className="responsive_nav">
        {isModalActive && (
          <AuthContainer
            show={isModalActive}
            onHide={() => dispatch(setModal(false))}
          />
        )}

        <nav className="navbar navbar-expand-lg navbar-light bg-white w-100 mx-0 res_nav">
          <div className="container-fluid d-flex justify-content-between">
            <div className="main_logo my-2">
              <img src={logo} alt="Instant Awesome Kids" />
            </div>

            <div>
              <div className="r_nav d-flex gap-3 align-items-center">
                <AiOutlineSearch
                  className="cursor-pointer search_icon p-2"
                  style={{
                    color: "rgba(0,0,0,0.3)",
                    backgroundColor: "rgba(0,0,0,0.05)",
                    borderRadius: "50%",
                  }}
                  size={34}
                />
                <Link to="/upload">
                  <button className="btn fw-bold py-2 btn-outline-primary nav_btn">
                    + Create
                  </button>
                </Link>
                <span
                  onClick={handleMenuClick}
                  className="fs-4 navbar-toggler-icon"
                ></span>
              </div>
            </div>
          </div>
          {isNavActive && (
            <div className="login_container">
              {isAuthenticated ? (
                <button
                  className="btn btn-primary py-2 nav_btn"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              ) : (
                <button
                  className="btn btn-primary py-2 nav_btn"
                  onClick={() => dispatch(setModal(true))}
                >
                  Log in
                </button>
              )}
            </div>
          )}
        </nav>
      </div>
    </>
  );
};

export default Navbar;
