import React from "react";
import Pages from "./Pages";
import Scroll from "./components/Scroll";
import { ToastContainer } from "react-toastify";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { endLoading, setModal } from "./redux/slices/globalSlice";
import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  const { isLoading, isModalActive } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading) {
      dispatch(endLoading());
    }
    if (isModalActive) {
      dispatch(setModal(false));
    }
  }, []);

  return (
    <>
      <GoogleOAuthProvider clientId="690145571299-avpek8p3n49iecndjbeoqvlaqbqil0af.apps.googleusercontent.com">
        <ToastContainer
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Scroll />
        {/* <Header /> */}
        <Pages />
        {/* {location.pathname !== "/chat" && <Footer2 />} */}
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
