import React from "react";
import logo from "../../assets/img/logo.png";
import aboutData from "./CardData";
import { Link } from "react-router-dom";
import video1 from "../../assets/mp4/1.mp4";
import video2 from "../../assets/mp4/2.mp4";
import video3 from "../../assets/mp4/3.mp4";
import video4 from "../../assets/mp4/4.mp4";
import video5 from "../../assets/mp4/5.mp4";
import video6 from "../../assets/mp4/6.mp4";
import video7 from "../../assets/mp4/7.mp4";
import video8 from "../../assets/mp4/8.mp4";
import Footer from "../../components/Footer";

const AboutUs = () => {
  return (
    <>
      <div className="py-2">
        <div className="main_logo align-items-center justify-content-start my-2 px-4">
          {/* <AiOutlineMenu size={30} className="mx-4" /> */}
          <Link to={"/"}>
            <img src={logo} alt="Instant Awesome Kids" />
          </Link>
        </div>
      </div>
      {aboutData.map(({ heading, text1, text2 }, index) => {
        let video;
        let video_2;
        if (index === 0) {
          video = video1;
          video_2 = video5;
        }
        if (index === 1) {
          video = video2;
          video_2 = video6;
        }
        if (index === 2) {
          video = video3;
          video_2 = video7;
        }
        if (index === 3) {
          video = video4;
          video_2 = video8;
        }

        return (
          <div key={index} className="card about_card mb-4">
            <div
              className="d-flex video_text_box"
              style={index % 2 > 0 ? { flexDirection: "row-reverse" } : null}
            >
              <div className="col-6 about_video">
                <video autoPlay controls loop muted>
                  <source src={video} type="video/mp4" />
                </video>
                <video autoPlay controls loop muted>
                  <source src={video_2} type="video/mp4" />
                </video>
              </div>
              <div className="col-6 px-5 d-flex justify-content-center flex-column about_text">
                <h2 className="about_us_heading">{heading}</h2>
                <p>{text1}</p>
                <p>{text2}</p>
              </div>
            </div>
          </div>
        );
      })}
      <Footer />
    </>
  );
};

export default AboutUs;
