import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import Settings from "../components/Modals/Settings";
import ChatBox from "../components/ChatBox";
import ChatUsers from "../components/ChatUsers";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

const Chat = () => {
  const [settingsModal, setSettingsModal] = useState(false);
  const [activeChatUser, setActiveChatUser] = useState(null);

  const handleChatUserClick = (userId) => {
    setActiveChatUser(userId);
  };

  return (
    <div className="bg-gray">
      <Navbar />
      {settingsModal && (
        <Settings show={settingsModal} onHide={() => setSettingsModal(false)} />
      )}
      <section className="chat-container py-3">
        <div className="container chat_box my-2 h-100">
          <div className="row">
            <div className="col-md-12">
              <div className="card-body chat-body">
                <div className="d-flex justify-content-between">
                  <div className="back-icon col-2 me-3 mt-2">
                    <Link to={"/home"}>
                      <BiArrowBack size={22} />
                    </Link>
                  </div>
                  <div
                    className="col-sm-3 col-md-4 col-xl-4 mb-4 mb-md-0"
                    style={{
                      borderRight: "1px solid rgba(252, 252, 252, 0.2)",
                    }}
                  >
                    <div className="card" style={{ borderRadius: "15px" }}>
                      <div className="chat-header py-3 px-4 d-flex align-items-center justify-content-between">
                        <h3 className="m-0">Messages</h3>
                        <i onClick={() => setSettingsModal(true)}>
                          <FiSettings
                            size={25}
                            className="mb-1 cursor-pointer"
                          />
                        </i>
                      </div>
                      <div
                        data-mdb-perfect-scrollbar="true"
                        style={{
                          position: "relative",
                        }}
                        className="chat-section"
                      >
                        <ul className="list-unstyled mb-0">
                          <ChatUsers
                            active={activeChatUser === 1}
                            onClick={() => handleChatUserClick(1)}
                          />
                          <ChatUsers
                            active={activeChatUser === 2}
                            onClick={() => handleChatUserClick(2)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ChatBox />
                </div>
              </div>
              {/* responsive chat page  */}
              <div className="card-body responsive-chat">
                <div className="d-flex justify-content-between">
                  {activeChatUser && (
                    <div
                      style={{ width: "25px", height: "25px" }}
                      className="back-icon col-2 me-1 mt-2"
                    >
                      <BiArrowBack
                        onClick={() => setActiveChatUser(null)}
                        size={15}
                      />
                    </div>
                  )}
                  <div
                    className={`col-12 mb-4 mb-md-0 ${
                      activeChatUser ? "d-none" : ""
                    }`}
                    style={{
                      borderRight: "1px solid rgba(252, 252, 252, 0.2)",
                    }}
                  >
                    <div className="card" style={{ borderRadius: "15px" }}>
                      <div className="chat-header py-3 px-4 d-flex align-items-center justify-content-between">
                        <h3 className="m-0">Messages</h3>
                        <i>
                          <FiSettings
                            size={25}
                            className="mb-1 cursor-pointer"
                          />
                        </i>
                      </div>
                      <div
                        data-mdb-perfect-scrollbar="true"
                        style={{
                          position: "relative",
                        }}
                        className="chat-section"
                      >
                        <ul className="list-unstyled mb-0">
                          <ChatUsers
                            active={activeChatUser === 1}
                            onClick={() => handleChatUserClick(1)}
                          />
                          <ChatUsers
                            active={activeChatUser === 2}
                            onClick={() => handleChatUserClick(2)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                  {activeChatUser && (
                    <ChatBox onGoBack={() => setActiveChatUser(null)} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Chat;

//  Responsive chat page

// const Chat = () => {

//   return (
//     <div className="bg-gray responsive-chat">
//       <Navbar />
//       <section className="chat-container py-3">
//         <div className="container chat_box my-2 h-100">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="card-body">
//                 <div className="d-flex justify-content-between">
//                   <div className="back-icon col-2 me-3 mt-2">
//                     {activeChatUser && (
//                       <button
//                         className="btn btn-primary"
//                         onClick={() => setActiveChatUser(null)}
//                       >
//                         <BiArrowBack size={22} />
//                       </button>
//                     )}
//                   </div>
//                   <div
//                     className={`col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0 ${
//                       activeChatUser ? "d-none" : ""
//                     }`}
//                     style={{
//                       borderRight: "1px solid rgba(252, 252, 252, 0.2)",
//                     }}
//                   >
//                     <div className="card" style={{ borderRadius: "15px" }}>
//                       <div className="chat-header py-3 px-4 d-flex align-items-center justify-content-between">
//                         <h3 className="m-0">Messages</h3>
//                         <i>
//                           <FiSettings
//                             size={25}
//                             className="mb-1 cursor-pointer"
//                           />
//                         </i>
//                       </div>
//                       <div
//                         data-mdb-perfect-scrollbar="true"
//                         style={{
//                           position: "relative",
//                         }}
//                         className="chat-section"
//                       >
//                         <ul className="list-unstyled mb-0">
//                           <ChatUsers
//                             active={activeChatUser === 1}
//                             onClick={() => handleChatUserClick(1)}
//                           />
//                           <ChatUsers
//                             active={activeChatUser === 2}
//                             onClick={() => handleChatUserClick(2)}
//                           />
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                   {activeChatUser && (
//                     <ChatBox onGoBack={() => setActiveChatUser(null)} />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Chat;
