import React, { useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import Navbar from "../components/Navbar";
import { useSearchQuery } from "../services/userService";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BsPlay } from "react-icons/bs";
import FullVideo from "../components/FullVideo";

const Search = () => {
  const [activeTab, setActiveTab] = useState("Accounts");
  const [index, setIndex] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [fullVideo, setFullVideo] = useState(false);
  const [query, setQuery] = useState("");
  const location = useLocation();

  const { data: searchResults, refetch } = useSearchQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get("query");
    if (queryParam) {
      setQuery(queryParam);
    }
  }, [location.search]);

  const handleTabHover = (tab) => {
    setActiveTab(tab);
  };
  const handleVideoClick = (i) => {
    setIndex(i);
    setFullVideo(true);
  };

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch]);

  return (
    <>
      {fullVideo && (
        <FullVideo
          video={searchResults?.videos[index]}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          onHide={() => setFullVideo(false)}
          index={index}
          setIndex={setIndex}
          setShouldRefetch={setShouldRefetch}
          allowDelete={true}
        />
      )}
      <MainLayout>
        <Navbar />

        <div className="main_video  m-auto">
          <div className="p-3">
            <ul className="d-flex gap-5 search_menu pb-2">
              <li
                className={` ${activeTab === "Accounts" ? "active" : ""}`}
                onClick={() => handleTabHover("Accounts")}
              >
                Accounts
              </li>
              <li
                className={` ${activeTab === "Videos" ? "active" : ""}`}
                onClick={() => handleTabHover("Videos")}
              >
                Videos
              </li>
            </ul>
          </div>
          {activeTab === "Accounts" ? (
            <div className="main_accounts">
              {searchResults?.users.map((user, index) => (
                <Link to={`/userProfile/${user.id}`}>
                  <div
                    key={index}
                    className="d-flex p-3 gap-3 align-items-center  account"
                  >
                    <div>
                      <img
                        className="avatar video-card-avatar"
                        src={user.profile}
                        crossOrigin="anonymous"
                        alt=""
                      />
                    </div>
                    <div className="">
                      <span className="fw-bold text-dark">{user.username}</span>
                      <p
                        className="d-flex align-items-center gap-3 fw-light m-0 text-dark"
                        style={{ fontSize: "14px" }}
                      >
                        {user.name && user.name !== "" && (
                          <p className="mb-0">{user.name}</p>
                        )}
                        <p className="mb-0">
                          <span className="fw-bold me-1">
                            {user.followers || 0}
                          </span>
                          Followers
                        </p>
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div>
              {searchResults?.videos.map((video, i) => (
                <div key={i} className="d-flex flex-column videoContainer">
                  <video
                    className="profileVideos"
                    onClick={() => handleVideoClick(i)}
                    controls={false}
                    muted={true}
                    autoPlay={false}
                  >
                    <source
                      src={
                        "https://iak-media.nyc3.digitaloceanspaces.com/" +
                        video.video
                      }
                    />
                  </video>
                  <p className="mb-0 mt-1 ms-1">{video.caption}</p>
                  <div className="d-flex align-items-center mt-1 justify-content-between">
                    <div>
                      <img
                        className="avatar  search_avatar "
                        src={video?.user.profile}
                        crossOrigin="anonymous"
                        alt=""
                      />
                      <span className=" mb-0 ms-1">
                        {video?.user?.username}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <BsPlay size={20} />
                      <span>{video?.views}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default Search;
