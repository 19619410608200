import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useSendOTPMutation,
  useSignupWithMobileMutation,
} from "../../../services/authService";
import { toast } from "react-toastify";

const SignupPhone = ({ signupEmail, dob, setUsername }) => {
  const [sendOTP] = useSendOTPMutation();
  const [register] = useSignupWithMobileMutation();
  const [otp, setOTP] = useState("");
  const [phone, setPhone] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [sending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const setMobile = (phone, data) => {
    setPhone(phone);
    setIsDisabled(phone.replace(data.dialCode, "")?.length !== 10);
    setValid(phone.replace(data.dialCode, "")?.length === 10);
  };

  const startTimer = (t) => {
    setSeconds(t);
    let s = t;
    var interval = setInterval(() => {
      s -= 1;
      setSeconds(s);
      if (s <= 0) {
        setSent(false);
        setIsDisabled(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleOTP = (e) => {
    e.preventDefault();
    if (sending) {
      return;
    }
    setIsSending(true);
    sendOTP({ phone, usingPhone: true }).then((res) => {
      setIsSending(false);
      if (!res.error) {
        setSent(true);
        setIsDisabled(true);
        startTimer(60);
      }
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (dob.month === 0 || dob.day === 0 || dob.year === 0) {
      return toast.error("Invalid date of birth");
    }
    setLoading(true);
    register({
      dob: `${dob.day}/${dob.month}/${dob.year}`,
      phone,
      otp,
      usingPhone: true,
    }).then((res) => {
      setLoading(false);
      if (!res.error) {
        setUsername();
      }
    });
  };

  const changeOTP = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setOTP(numericValue.length < 7 ? numericValue : otp);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <label style={{ fontSize: "14px", fontWeight: "500" }}>Phone</label>
        <span onClick={signupEmail} className="phone_login_text cursor-pointer">
          Sign Up with email
        </span>
      </div>
      <form
        onSubmit={submitForm}
        className=" login_form d-flex flex-column gap-2"
      >
        <div class="input_container input-group input-group-lg">
          {/* <input
            className="input_container"
            type="text"
            placeholder="Number Phone"
          /> */}
          <PhoneInput
            country={"us"}
            inputClass="input_container w-100 h-100"
            value={phone}
            onChange={setMobile}
            enableSearch={true}
          />
        </div>

        <div class="input_container input-group input-group-lg">
          <input
            type="text"
            className=" form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            placeholder="Enter 6-digit code"
            value={otp}
            onChange={changeOTP}
          />
          <button
            onClick={handleOTP}
            className={`code input-group-text`}
            id="inputGroup-sizing-lg"
            disabled={isDisabled}
          >
            {sending ? (
              <div class="loadingio-spinner-rolling-x0a55a0iw5">
                <div class="ldio-6g08kqzye5a">
                  <div></div>
                </div>
              </div>
            ) : sent ? (
              `Resend in ${seconds}s`
            ) : (
              "Send code"
            )}
          </button>
        </div>
        <button
          disabled={otp.length !== 6 || !isValid || isLoading}
          className="code mt-3 py-2 mb-5"
        >
          {isLoading ? (
            <div class="loadingio-spinner-rolling-x0a55a0iw5">
              <div class="ldio-6g08kqzye5a">
                <div></div>
              </div>
            </div>
          ) : (
            "Next"
          )}
        </button>
      </form>
    </>
  );
};

export default SignupPhone;
