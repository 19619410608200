import {
  isFulfilled,
  isPending,
  isRejected,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { endLoading, startLoading } from "../redux/slices/globalSlice";

export const rtkQueryLoader =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isPending(action) && !getState().global.isLoading) {
      dispatch(startLoading());
    }
    if (
      isFulfilled(action) ||
      isRejected(action) ||
      isRejectedWithValue(action)
    ) {
      dispatch(endLoading());
    }

    return next(action);
  };
