import moment from "moment/moment";
import React from "react";

// import { FiHeart } from "react-icons/fi";
// import { MdAlternateEmail } from "react-icons/md";

const CommentBox = ({ comment }) => {
  return (
    <div className="mx-4 my-1 d-flex justify-content-between align-items-center">
      <div className="d-flex">
        <img
          className="avatar comment_avatar"
          src={comment.user.profile}
          crossOrigin="anonymous"
          alt=""
        />
        <div className="ms-3">
          <p className="mb-0" style={{ fontWeight: "600" }}>
            {comment.user.username}
          </p>
          <p className="mb-1" style={{ fontWeight: "500" }}>
            {comment.comment}
          </p>
          <span className="text-secondary">
            {moment(comment.createdAt).fromNow()}
          </span>
          {/* <div><span className="text-secondary ms-4">Reply</span></div> */}
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
