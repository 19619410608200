import { useState, useEffect, useRef } from "react";
import { BsEmojiSmile } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa";
import profile from "../assets/img/profile.png";
import moment from "moment";

const ChatBox = () => {
  const bottomRef = useRef();
  const currentChatRef = useRef();

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  // useEffect(() => {
  //   if (refetch) {
  //     refetch();
  //   }
  // }, [id, refetch]);

  const [message, setMessage] = useState("");

  return (
    <div className="w-100 ms-3">
      <div className="card p-1 chatbox" style={{ borderRadius: "15px" }}>
        <div className="p-3 texting-header d-flex align-items-center">
          <div className="position-relative">
            <img
              src={profile}
              alt=""
              className="chat-picture"
              crossOrigin="anonymous"
            />
            <span className="bg-success badge-dot"></span>
          </div>
          <h4 className="ms-3 mb-0">Alex Sun</h4>
        </div>
        <div className="texting-section">
          <div
            className="pt-3 px-3 position-relative"
            data-mdb-perfect-scrollbar="true"
          >
            <div>
              <div className="d-flex flex-row justify-content-end">
                <div>
                  <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                    Hi, How are you?
                  </p>
                  <p className="small me-3 mb-3 rounded-3 text-secondary">
                    12:30 PM
                  </p>
                </div>
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
                <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3 text-white"
                    style={{ background: "#353b48" }}
                  >
                    I am good
                  </p>
                  <p className="small ms-3 mb-3 rounded-3 text-secondary float-end">
                    01:21 AM
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
                <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3 text-white"
                    style={{ background: "#353b48" }}
                  >
                    I am good
                  </p>
                  <p className="small ms-3 mb-3 rounded-3 text-secondary float-end">
                    01:21 AM
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
                <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3 text-white"
                    style={{ background: "#353b48" }}
                  >
                    I am good
                  </p>
                  <p className="small ms-3 mb-3 rounded-3 text-secondary float-end">
                    01:21 AM
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
                <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3 text-white"
                    style={{ background: "#353b48" }}
                  >
                    I am good
                  </p>
                  <p className="small ms-3 mb-3 rounded-3 text-secondary float-end">
                    01:21 AM
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
                <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3 text-white"
                    style={{ background: "#353b48" }}
                  >
                    I am good
                  </p>
                  <p className="small ms-3 mb-3 rounded-3 text-secondary float-end">
                    01:21 AM
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
                <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3 text-white"
                    style={{ background: "#353b48" }}
                  >
                    I am good
                  </p>
                  <p className="small ms-3 mb-3 rounded-3 text-secondary float-end">
                    01:21 AM
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="chat-profile">
                  <img src={profile} alt="avatar 1" crossOrigin="anonymous" />
                </div>
                <div>
                  <p
                    className="small p-2 ms-3 mb-1 rounded-3 text-white"
                    style={{ background: "#353b48" }}
                  >
                    I am good
                  </p>
                  <p className="small ms-3 mb-3 rounded-3 text-secondary float-end">
                    01:21 AM
                  </p>
                </div>
              </div>
            </div>
            <div className="test" ref={bottomRef}></div>
          </div>
        </div>
        <form className="text-muted d-flex justify-content-start align-items-center p-3 mt-3 message-box">
          <div className="message-input">
            <input
              type="text"
              className="ms-3 message-input"
              placeholder="Type message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="mx-2 text-secondary">
              <i className="cursor-pointer">
                <BsEmojiSmile size={22} />
              </i>
            </div>
          </div>
          <button className="mx-3 send-btn">
            <i className="cursor-pointer">
              <FaRegPaperPlane size={26} />
            </i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatBox;
