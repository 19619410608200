import React, { useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import Navbar from "../components/Navbar";
import VideoCard from "../components/VideoCard";
import { RiHome6Fill } from "react-icons/ri";
import { FaTv, FaUser } from "react-icons/fa";
import { useGetVideosQuery } from "../services/videoService";
import MobileVideoCard from "../components/MobileVideoCard";
import { useState } from "react";
import { useSelector } from "react-redux";
import FullVideo from "../components/FullVideo";

const Home = () => {
  const { user } = useSelector((state) => state.authData);
  const [isMuted, setIsMuted] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFullVideo, setIsFullVideo] = useState(false);
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [videos, setVideos] = useState([]);
  const { data, refetch } = useGetVideosQuery(
    { currentPage, user: user?.id },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const width = window.innerWidth || null;

  useEffect(() => {
    if (
      currentVisibleIndex === videos?.length - 2 &&
      currentPage < data.totalPages
    ) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentVisibleIndex]);

  useEffect(() => {
    if (data?.results && data?.results.length > 0) {
      if (currentPage === 1) {
        setVideos([...data?.results]);
      } else {
        setVideos([...videos, ...data?.results]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch]);

  return (
    <>
      {isFullVideo && (
        <FullVideo
          video={videos[currentVisibleIndex]}
          setIndex={setCurrentVisibleIndex}
          onHide={() => setIsFullVideo(false)}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          index={currentVisibleIndex}
          length={videos?.length}
          setShouldRefetch={setShouldRefetch}
          allowDelete={false}
        />
      )}
      <MainLayout>
        <Navbar />
        {width > 500 && (
          <div className="home_page">
            {videos?.map((video, index) => (
              <VideoCard
                video={video}
                key={video.id}
                index={index}
                currentVisibleIndex={currentVisibleIndex}
                setCurrentVisibleIndex={setCurrentVisibleIndex}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                setFullVideo={() => setIsFullVideo(true)}
              />
            ))}
          </div>
        )}
      </MainLayout>

      {/* resonsive home page design  */}
      <div className="responsive_home">
        <div className="home_bar d-flex">
          <div className="left_icon d-flex gap-4 align-items-center">
            <RiHome6Fill fill="#ec1c24" className="icon" size={40} />
            <FaUser size={30} className="icon" />
            <FaTv size={30} className="icon" />
          </div>
          <div className="account d-flex gap-3  align-items-center">
            {/* <div className="profile-img">
              <img src={profile} alt="" />
            </div>{" "}
            <div className="profile-img">
              <img src={profile} alt="" />
            </div>{" "} */}
          </div>
        </div>
        {width < 501 && (
          <div className="main_home ">
            {videos?.map((video, index) => (
              <MobileVideoCard
                video={video}
                key={video.id}
                index={index}
                currentVisibleIndex={currentVisibleIndex}
                setCurrentVisibleIndex={setCurrentVisibleIndex}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
