import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineEdit } from "react-icons/ai";
import { useState } from "react";
import { useUpdateProfileMutation } from "../../services/userService";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/slices/authSlice";

const EditProfile = ({ show, onHide, user }) => {
  const { user: oldUser } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  const [updateProfile] = useUpdateProfileMutation();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [data, setData] = useState({
    username: user.username,
    name: user.name,
    bio: user.bio,
  });

  const fileInputRef = useRef();
  const handleProfileChange = (e) => {
    const profileEdit = e.target.files[0];
    setProfilePicture(profileEdit);
    setSelectedProfile(URL.createObjectURL(profileEdit));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleUpdate = () => {
    let fd = data;
    if (selectedProfile) {
      fd = new FormData();
      fd.append("file", profilePicture);
      fd.append("username", data.username);
      fd.append("name", data.name);
      fd.append("bio", data.bio);
    }
    updateProfile(fd).then((res) => {
      if (!res.error) {
        dispatch(updateUser({ ...oldUser, ...res.data }));
        onHide();
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="settings-modal"
        dialogClassName="custom-modal"
      >
        <div className="d-flex justify-content-between align-items-center pt-4 pb-3 px-4">
          <h4 className="mb-0">Edit Profile</h4>
          <RxCross2
            onClick={onHide}
            size={22}
            className="text-dark cursor-pointer"
          />
        </div>
        <hr className="my-0" />
        <div className="px-4 mt-3">
          <div className="d-flex ">
            <span className="mb-0">Profile photo</span>
            <div className="d-flex justify-content-center col-md-8 position-relative">
              <img
                className="avatar Edit-avatar "
                src={selectedProfile || user.profile}
                crossOrigin="anonymous"
                alt=""
              />
              <div
                onClick={() => fileInputRef.current.click()}
                className="bg-white d-flex justify-content-center align-items-center rounded-pill editIcon cursor-pointer"
              >
                <AiOutlineEdit size={22} />
                <input
                  type="file"
                  ref={fileInputRef}
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleProfileChange}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex mb-3">
            <label htmlFor="" className="col-md-3">
              Username
            </label>
            <div className="edit_input input-group input-group-lg">
              <input
                onChange={handleChange}
                type="text"
                name="username"
                value={data.username}
              />
              {/* <small className="mt-3 mb-2" style={{ fontSize: "12.5px" }}>
                www.istantawesomekids.com/@KevinBrothers456
              </small> */}
              <small style={{ fontSize: "12.5px" }}>
                Usernames can only contain letters, numbers, underscores, and
                periods. Changing your username will also change your profile
                link.
              </small>
            </div>
          </div>
          <hr className="my-0" />

          <div className="d-flex my-3">
            <label htmlFor="" className="col-md-3">
              Name
            </label>
            <div className="edit_input input-group input-group-lg">
              <input
                onChange={handleChange}
                type="text"
                name="name"
                value={data.name}
              />
              <small className="mt-3 mb-2" style={{ fontSize: "12.5px" }}>
                Your nickname can only be changed once every 7 days.
              </small>
            </div>
          </div>
          <hr className="my-0" />

          <div className="d-flex my-3">
            <label htmlFor="" className="col-md-3">
              Bio
            </label>
            <div className="edit_input input-group input-group-lg">
              <textarea
                value={data.bio}
                onChange={handleChange}
                name="bio"
                rows="6"
              ></textarea>
              <small className="mt-3 mb-2" style={{ fontSize: "12.5px" }}>
                0/80
              </small>
            </div>
          </div>
        </div>
        <hr className="my-0" />
        <div className="d-flex justify-content-end py-4">
          <button
            onClick={onHide}
            className="btn shadow-none bg-light text-dark btn-outline-secondary px-3"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdate}
            className="btn shadow-none border-radius-0 border-0 px-4 mx-3 bg-primary text-white"
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditProfile;
