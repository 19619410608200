import React, { useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { BsFillHeartFill } from "react-icons/bs";
import { FaCommentDots } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import coin from "../assets/img/coin.png";
import { MutatingDots } from "react-loader-spinner";
import { BsLink45Deg } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";

import {
  FaWhatsapp,
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedinIn,
} from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setModal } from "../redux/slices/globalSlice";
import {
  useLikeVideoMutation,
  useFollowUserMutation,
  useShareVideoMutation,
} from "../services/videoService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const VideoCard = ({
  video,
  index,
  currentVisibleIndex,
  setCurrentVisibleIndex,
  isMuted,
  setIsMuted,
  setFullVideo,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  const [shareVideo] = useShareVideoMutation();
  const [likeVideo] = useLikeVideoMutation();
  const [followerUser] = useFollowUserMutation();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.authData);
  const playerRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [isLiked, setIsLiked] = useState(video.liked);
  const [isFollowed, setIsFollowed] = useState(video.followed);
  const [likes, setLikes] = useState(video.likes);
  const [shares, setShares] = useState(video?.shares || 0);

  useEffect(() => {
    setIsVisible(inView);
    if (inView) {
      setCurrentVisibleIndex(index);
    }
  }, [inView]);

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const handleClick = (e) => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (isVisible && !isPlaying && index === currentVisibleIndex) {
      playerRef.current.currentTime = 0;
      playerRef.current.play().then(() => {
        setIsPlaying(true);
      });
    } else if (isPlaying && index !== currentVisibleIndex) {
      playerRef.current.pause();
      setIsPlaying(false);
    }
  }, [isVisible, index, currentVisibleIndex, shouldPlay]);

  const handleLike = () => {
    if (!isAuthenticated) {
      dispatch(setModal(true));
    } else {
      likeVideo({ video: video.id }).then((res) => {
        if (!res.error) {
          setIsLiked(!isLiked);
          setLikes(isLiked ? likes - 1 : likes + 1);
        }
      });
    }
  };

  const handleFollower = () => {
    if (!isAuthenticated) {
      dispatch(setModal(true));
    } else {
      followerUser({ user: video.user.id }).then((res) => {
        if (!res.error) {
          setIsFollowed(!isFollowed);
        }
      });
    }
  };

  const handleFullVideo = () => {
    handleClick();
    setFullVideo();
  };

  const handleMouseEnter = () => {
    setShowBox(true);
  };
  const handleMouseLeave = () => {
    setShowBox(false);
  };

  const handleShare = () => {
    shareVideo(video.id).then((res) => {
      if (!res.error) {
        setShares(shares + 1);
      }
    });
  };

  const copyLink = () => {
    handleShare();
    navigator.clipboard.writeText(
      `${window.location.origin}/video/${video?.id}`
    );
    toast.success("Link copied");
  };
  const shareWithWhatsApp = () => {
    handleShare();
    const videoLink = `${window.location.origin}/video/${video?.id}`;
    const message = encodeURIComponent(
      `Hey! Checkout this video\n${videoLink}`
    );
    const whatsappURL = `whatsapp://send?text=${message}`;
    window.open(whatsappURL);
  };
  const shareOnFacebook = () => {
    handleShare();
    const videoLink = `${window.location.origin}/video/${video?.id}`;
    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      videoLink
    )}`;
    window.open(facebookURL, "_blank");
  };

  const shareOnTwitter = () => {
    handleShare();
    const videoLink = `${window.location.origin}/video/${video?.id}`;
    const text = encodeURIComponent("Check out this video!");
    const twitterURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      videoLink
    )}&text=${text}`;
    window.open(twitterURL, "_blank");
  };
  const shareOnTelegram = () => {
    handleShare();
    const videoLink = `${window.location.origin}/video/${video?.id}`;
    const text = encodeURIComponent("Check out this video!");
    const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(
      videoLink
    )}&text=${text}`;
    window.open(telegramURL, "_blank");
  };
  const shareOnPinterest = () => {
    handleShare();
    const videoLink = `${window.location.origin}/video/${video?.id}`;
    const imageUrl = coin;
    const pinterestURL = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      videoLink
    )}&media=${encodeURIComponent(imageUrl)}&description=${encodeURIComponent(
      "Check out this video!"
    )}`;
    window.open(pinterestURL, "_blank");
  };
  const shareOnLinkedIn = () => {
    handleShare();
    const videoLink = `${window.location.origin}/video/${video?.id}`;
    const linkedInURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      videoLink
    )}`;
    window.open(linkedInURL, "_blank");
  };

  return (
    <>
      <div className="row main_video d-flex justify-content-between m-auto">
        <div className="left col-6 m-xl-5">
          <div className="d-flex mb-3 gap-3 align-items-center">
            <div>
              <Link to={`/userProfile/${video?.user?.id}`}>
                <img
                  className="avatar video-card-avatar"
                  src={video?.user?.profile}
                  crossOrigin="anonymous"
                  alt=""
                />
              </Link>
            </div>
            <div className="profile_details">
              <p className="d-flex align-items-center m-0">
                <span className="fw-bold me-3">{video?.user?.username}</span>
                {/* Shredders */}
              </p>
              {/* <span>Episode | best oddly satisfying </span> */}
            </div>
          </div>
          <div className="mb-2">
            <span className="cardSpan">{video.caption}</span>
          </div>
          {/* <div className="d-flex gap-3 mt-4">
            <MdLocationOn
              className="text-light"
              size={20}
              style={{ backgroundColor: "#13BD90", borderRadius: "3px" }}
            />
            <p className="cardSpan">87835 Ashly Path</p>
          </div> */}
          {/* <div className="d-flex gap-3">
            <FaMusic
              size={20}
              style={{ borderRadius: "3px" }}
              className="text-light bg-dark px-1"
            />
            <p className="about_music">
              Love You So - The King Khan & BBQ Show
            </p>
          </div> */}
          <div className="video-player">
            <div className="video-loader" ref={ref}>
              <MutatingDots
                height="100"
                width="100"
                color="#ee353b"
                secondaryColor="aquamarine"
                radius="12.5"
                visible={isLoading}
                ariaLabel="loading"
                // wrapperStyle
                // wrapperClass
              />
            </div>
            <video
              ref={playerRef}
              loop
              playsInline
              muted={isMuted}
              onLoadedData={() => setIsLoading(false)}
              onWaiting={() => setIsLoading(true)}
              onPlaying={() => setIsLoading(false)}
              className="cursor-pointer"
              onClick={handleFullVideo}
            >
              <source
                src={
                  "https://iak-media.nyc3.digitaloceanspaces.com/" + video.video
                }
                type="video/mp4"
              />
            </video>
            <div className="d-flex justify-content-between video-controls align-items-center">
              {isPlaying ? (
                <FaPause
                  onClick={handleClick}
                  size={20}
                  className="cursor-pointer"
                />
              ) : (
                <FaPlay
                  onClick={handleClick}
                  size={20}
                  className="cursor-pointer"
                />
              )}
              {isMuted ? (
                <HiOutlineVolumeOff
                  onClick={handleMute}
                  className="cursor-pointer"
                  size={25}
                />
              ) : (
                <HiOutlineVolumeUp
                  onClick={handleMute}
                  className="cursor-pointer"
                  size={25}
                />
              )}
            </div>
            {/* <VideoPlayer
              controls={false}
              src={
                "https://api.instantawesomekids.com/v1/media/1690238231345.mp4"
              }
              poster={
                "https://api.instantawesomekids.com/v1/media/1690238231345.mp4"
              }
              onReady={onPlayerReady}
              autoplay={true}
              width={"100%"}
              height={"100%"}
            /> */}
          </div>
        </div>

        <div className="right col-2 mt-5">
          <div className="mb-4">
            {video.user.id !== user?.id && (
              <button
                onClick={handleFollower}
                className={`btn py-2 px-4 ${
                  isFollowed ? "btn-outline-primary text-bold" : "btn-primary"
                }`}
              >
                {isFollowed ? "Following" : "Follow"}
              </button>
            )}
          </div>
          <div className="d-flex gap-2 flex-column align-items-center mb-4">
            <div
              className={`actions_btn ${isLiked && "active"}`}
              onClick={handleLike}
            >
              <BsFillHeartFill size={25} />
            </div>
            <span className="followers">{likes}</span>
          </div>{" "}
          <div className="d-flex gap-2 flex-column align-items-center mb-4">
            <div onClick={handleFullVideo} className="actions_btn">
              <FaCommentDots size={25} />
            </div>
            <span className="followers">{video.comments || 0}</span>
          </div>
          <div
            className="d-flex gap-2 flex-column align-items-center mb-4 position-relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="actions_btn">
              <BsShareFill size={25} />
            </div>
            <span className="followers">{shares}</span>
            {showBox && (
              <div
                className="d-flex flex-column  shareBox shadow px-2 py-3"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="d-flex gap-3 shareLinks" onClick={copyLink}>
                  <div className="video-profile-controlsMedia bg-primary text-light ">
                    <BsLink45Deg size={18} />
                  </div>
                  <span>Copy Link</span>
                </div>
                <div
                  className="d-flex gap-3 shareLinks"
                  onClick={shareWithWhatsApp}
                >
                  <div
                    className="video-profile-controlsMedia text-light"
                    style={{ background: "#59CE72" }}
                  >
                    <FaWhatsapp size={17} />
                  </div>
                  <span>Share To WhatsApp</span>
                </div>
                <div
                  className="d-flex gap-3 shareLinks"
                  onClick={shareOnFacebook}
                >
                  <div
                    className="video-profile-controlsMedia text-light"
                    style={{ background: "#1877F2" }}
                  >
                    <FaFacebookF size={17} />
                  </div>
                  <span>Share To Facebook</span>
                </div>
                <div
                  className="d-flex gap-3 shareLinks"
                  onClick={shareOnTwitter}
                >
                  <div className="video-profile-controlsMedia bg-info text-light ">
                    <FaTwitter size={17} />
                  </div>
                  <span>Share To Twitter</span>
                </div>
                {!showMoreOptions && (
                  <div
                    onClick={toggleMoreOptions}
                    className="text-center shareLinks p-0"
                  >
                    <FaChevronDown size={20} />
                  </div>
                )}

                {showMoreOptions && (
                  <div>
                    <div
                      className="d-flex gap-3 shareLinks"
                      onClick={shareOnTelegram}
                    >
                      <div className="video-profile-controlsMedia bg-info text-light ">
                        <FaTelegramPlane size={17} />
                      </div>
                      <span>Share To Telegram</span>
                    </div>

                    <div
                      className="d-flex gap-3 shareLinks"
                      onClick={shareOnPinterest}
                    >
                      <div className="video-profile-controlsMedia bg-danger text-light ">
                        <FaPinterestP size={17} />
                      </div>
                      <span>Share To Pinterest</span>
                    </div>

                    <div
                      className="d-flex gap-3 shareLinks"
                      onClick={shareOnLinkedIn}
                    >
                      <div
                        className="video-profile-controlsMedia text-light"
                        style={{ background: "#1877F2" }}
                      >
                        <FaLinkedinIn size={17} />
                      </div>
                      <span>Share To LinkedIn</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="d-flex gap-2 flex-column align-items-center mb-4">
            <div className="actions_btn">
              <img src={coin} />
            </div>
            <span className="followers">IAK Coin</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoCard;
