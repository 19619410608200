import React, { useState } from "react";
import { RiEyeCloseLine } from "react-icons/ri";
import {
  useLoginWithPhoneMutation,
  useLoginWithPhonePasswordMutation,
  useSendOTPMutation,
} from "../../../services/authService";
import PhoneInput from "react-phone-input-2";

const PhoneLogin = ({
  handleSignup,
  handleEmail,
  onHide,
  resetPassword,
  resetScreen,
}) => {
  const [sendOTP] = useSendOTPMutation();
  const [loginWithPhone] = useLoginWithPhoneMutation();
  const [loginWithPhonePassword] = useLoginWithPhonePasswordMutation();
  const [sent, setSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [phone, setPhone] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [sending, setIsSending] = useState(false);
  const [otp, setOTP] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [password, setPassword] = useState("");

  const setMobile = (phone, data) => {
    setPhone(phone);
    setIsDisabled(phone.replace(data.dialCode, "")?.length !== 10);
    setValid(phone.replace(data.dialCode, "")?.length === 10);
  };

  const startTimer = (t) => {
    setSeconds(t);
    let s = t;
    var interval = setInterval(() => {
      s -= 1;
      setSeconds(s);
      if (s <= 0) {
        setSent(false);
        setIsDisabled(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleOTP = (e) => {
    e.preventDefault();
    if (sending) {
      return;
    }
    setIsSending(true);
    sendOTP({ phone, usingPhone: true }).then((res) => {
      setIsSending(false);
      if (!res.error) {
        setSent(true);
        setIsDisabled(true);
        startTimer(60);
      }
    });
  };

  const changeOTP = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setOTP(numericValue.length < 7 ? numericValue : otp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isPassword) {
      loginWithPhone({ phone, otp, usingPhone: true }).then((res) => {
        setLoading(false);
        if (!res.error) {
          onHide();
        }
      });
    } else {
      loginWithPhonePassword({ phone, password, usingPhone: true }).then(
        (res) => {
          setLoading(false);
          if (!res.error) {
            onHide();
          }
        }
      );
    }
  };

  return (
    <div>
      <div className="phoneLogin">
        <h3 className="fw-bold fs-3 text-center mb-4">Log in </h3>
        <div className="px-5" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between mb-2">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>Phone</label>
            <span
              onClick={handleEmail}
              className="phone_login_text cursor-pointer"
            >
              Log in with email or username
            </span>
          </div>

          <form
            onSubmit={handleSubmit}
            className=" login_form d-flex flex-column gap-2"
          >
            <div class="input_container input-group input-group-lg">
              <PhoneInput
                country={"us"}
                inputClass="input_container w-100 h-100"
                value={phone}
                onChange={setMobile}
                enableSearch={true}
              />
            </div>
            {isPassword ? (
              <div className="input_container input-group input-group-lg">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <RiEyeCloseLine
                  className="close_eye"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            ) : (
              <div class="input_container input-group input-group-lg">
                <input
                  type="text"
                  className=" form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  placeholder="Enter 6-digit code"
                  value={otp}
                  onChange={changeOTP}
                />
                <button
                  onClick={handleOTP}
                  className={`code input-group-text`}
                  id="inputGroup-sizing-lg"
                  disabled={isDisabled}
                >
                  {sending ? (
                    <div class="loadingio-spinner-rolling-x0a55a0iw5">
                      <div class="ldio-6g08kqzye5a">
                        <div></div>
                      </div>
                    </div>
                  ) : sent ? (
                    `Resend in ${seconds}s`
                  ) : (
                    "Send code"
                  )}
                </button>
              </div>
            )}
            <div className="d-flex align-items-center">
              {isPassword && (
                <>
                  <span
                    onClick={() => {
                      resetScreen("phone");
                      resetPassword();
                    }}
                    className="phone_login_text cursor-pointer"
                  >
                    Forgot password?
                  </span>
                  <span className="line mx-3" style={{ height: "18px" }}></span>
                </>
              )}
              <span
                onClick={() => setIsPassword(!isPassword)}
                className="phone_login_text cursor-pointer"
              >
                Login with {isPassword ? "code" : "password"}
              </span>
            </div>
            <button
              disabled={
                (!isPassword && otp.length !== 6) ||
                !isValid ||
                isLoading ||
                (isPassword && password === "")
              }
              className="code mt-3 py-2 mb-5"
            >
              {isLoading ? (
                <div class="loadingio-spinner-rolling-x0a55a0iw5">
                  <div class="ldio-6g08kqzye5a">
                    <div></div>
                  </div>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>

        <hr />
        <div className="text-center">
          <span>Don't have an Account? </span>
          <span
            onClick={handleSignup}
            style={{ color: "#ec1c24" }}
            className="login_link cursor-pointer"
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
};

export default PhoneLogin;
