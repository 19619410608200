import { isFulfilled } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

/**
 * Log a warning and show a toast!
 */
export const rtkQuerySuccess = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isFulfilled(action)) {
    toast.success(action.payload?.message);
  }

  return next(action);
};
