import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

const Settings = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} className="settings-modal">
      <div className="rounded newchat">
        <div className="col-12 p-0">
          <div className="chat-header texting-header d-flex px-4 py-3 align-items-center justify-content-between newChat-bg rounded-top">
            <h3 className="m-0">Message settings</h3>
            <i onClick={onHide}>
              <RxCross2 size={25} className="mb-1 cursor-pointer" />
            </i>
          </div>
          <div className="py-3 px-4">
            <div className="settings-text mb-2">
              <h4>Who can send you direct messages</h4>
              <p>
                With any option, you can receive messages from users that you've
                sent messages to. Friends are your followers that you follow
                back.
              </p>
            </div>
            <div className="radio-button mb-4">
              <input type="radio" name="selector" id="friends" checked />
              <label htmlFor="friends">Friends</label>
            </div>
            <div className="radio-button">
              <input type="radio" name="selector" id="no" />
              <label htmlFor="no">No one</label>
            </div>
          </div>
          <div className="message-box p-4 d-flex justify-content-end">
            <button className="settings-btn cancel-btn" onClick={onHide}>
              Cancel
            </button>
            <button className="settings-btn save-btn" onClick={onHide}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
