import React, { useState } from "react";
import { RiLockFill } from "react-icons/ri";
import { BsLink45Deg } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";

import {
  FaWhatsapp,
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedinIn,
} from "react-icons/fa";
import MainLayout from "../layouts/MainLayout";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import { useGetUserProfileQuery } from "../services/userService";
import FullVideo from "../components/FullVideo";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UserDetail = () => {
  const { userId } = useParams();

  const { data: userProfileDetail, refetch } = useGetUserProfileQuery(userId, {
    refetchOnMountOrArgChange: true,
  });
  const { user } = useSelector((state) => state.authData);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [fullVideo, setFullVideo] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [index, setIndex] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };
  const [showBox, setShowBox] = useState(false);

  const handleBoxMouseEnter = () => {
    setShowBox(true);
  };

  const handleBoxMouseLeave = () => {
    setShowBox(false);
  };
  // ................
  const [activeTab, setActiveTab] = useState("Video");
  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleVideoClick = (i) => {
    setIndex(i);
    setFullVideo(true);
  };

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch]);

  return (
    <>
      {fullVideo && (
        <FullVideo
          video={
            activeTab === "Video"
              ? userProfileDetail.myVideos[index]
              : activeTab === "Liked"
              ? userProfileDetail.likedVideos[index].video
              : 0
          }
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          onHide={() => setFullVideo(false)}
          index={index}
          setIndex={setIndex}
          setShouldRefetch={setShouldRefetch}
          allowDelete={true}
          length={
            activeTab === "Video"
              ? userProfileDetail.myVideos?.length
              : activeTab === "Liked"
              ? userProfileDetail.likedVideos?.length
              : 0
          }
        />
      )}
      <MainLayout>
        <Navbar />

        <div className="row">
          <div className="col-md-12 mt-4 px-5">
            <div className="d-flex ">
              <img
                className="avatar Profile-avatar"
                src={userProfileDetail?.user.profile}
                crossOrigin="anonymous"
                alt=""
              />
              <div className="ms-3 mt-2">
                <h2 className="fw-bold mb-0">
                  {userProfileDetail?.user?.username}
                </h2>
                {userProfileDetail?.user?.name &&
                  userProfileDetail?.user?.name !== "" && (
                    <p className="fs-5 mb-0 mt-1">
                      {userProfileDetail?.user?.name}
                    </p>
                  )}

                <button className="btn fw-bold py-2 btn-primary nav_btn mt-2 ">
                  Follow
                </button>
              </div>

              {showBox && (
                <div
                  className="d-flex flex-column  shareBox shadow px-2 py-3"
                  onMouseEnter={handleBoxMouseEnter}
                  onMouseLeave={handleBoxMouseLeave}
                >
                  <div className="d-flex gap-3 shareLinks">
                    <div className="video-profile-controlsMedia bg-primary text-light ">
                      <BsLink45Deg size={18} />
                    </div>
                    <span>Copy Link</span>
                  </div>
                  <div className="d-flex gap-3 shareLinks">
                    <div
                      className="video-profile-controlsMedia text-light"
                      style={{ background: "#59CE72" }}
                    >
                      <FaWhatsapp size={17} />
                    </div>
                    <span>Share To WhatsApp</span>
                  </div>
                  <div className="d-flex gap-3 shareLinks">
                    <div
                      className="video-profile-controlsMedia text-light"
                      style={{ background: "#1877F2" }}
                    >
                      <FaFacebookF size={17} />
                    </div>
                    <span>Share To Facebook</span>
                  </div>
                  <div className="d-flex gap-3 shareLinks">
                    <div className="video-profile-controlsMedia bg-info text-light ">
                      <FaTwitter size={17} />
                    </div>
                    <span>Share To Twitter</span>
                  </div>
                  {!showMoreOptions && (
                    <div
                      onClick={toggleMoreOptions}
                      className="text-center shareLinks p-0"
                    >
                      <FaChevronDown size={20} />
                    </div>
                  )}

                  {showMoreOptions && (
                    <div>
                      <div className="d-flex gap-3 shareLinks">
                        <div className="video-profile-controlsMedia bg-info text-light ">
                          <FaTelegramPlane size={17} />
                        </div>
                        <span>Share To Telegram</span>
                      </div>

                      <div className="d-flex gap-3 shareLinks">
                        <div className="video-profile-controlsMedia bg-danger text-light ">
                          <FaPinterestP size={17} />
                        </div>
                        <span>Share To Pinterest</span>
                      </div>

                      <div className="d-flex gap-3 shareLinks">
                        <div
                          className="video-profile-controlsMedia text-light"
                          style={{ background: "#1877F2" }}
                        >
                          <FaLinkedinIn size={17} />
                        </div>
                        <span>Share To LinkedIn</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="d-flex align-items-center gap-3 mt-4">
              <div>
                <span className="fw-bold me-2">
                  {userProfileDetail?.following || 0}
                </span>
                <span>Following</span>
              </div>
              <div>
                <span className="fw-bold me-2">
                  {userProfileDetail?.followers || 0}
                </span>
                <span>Followers</span>
              </div>
              <div>
                <span className="fw-bold me-2">
                  {userProfileDetail?.likes || 0}
                </span>
                <span>Likes</span>
              </div>
            </div>
            <p className="mt-2">
              {user?.bio && user?.bio !== "" ? user.bio : "No bio yet."}
            </p>

            <div className="d-flex align-items-center mt-4">
              <p
                onClick={() => changeTab("Video")}
                className={`profileText cursor-pointer mb-0 px-4 downLine1 downLine  ${
                  activeTab === "Video" ? "tabUnderline" : "text-secondary"
                }`}
                style={{ fontWeight: "500" }}
              >
                Videos
              </p>
              <div
                onClick={() => changeTab("Favourites")}
                className={`px-4 d-flex justify-content-center align-items-center  ${
                  activeTab === "Favourites" ? "tabUnderline" : "text-secondary"
                }`}
              >
                <RiLockFill size={19} />
                <p
                  className="profileText cursor-pointer mb-0 ms-2 "
                  style={{ fontWeight: "500" }}
                >
                  Favourites
                </p>
              </div>
              <div
                onClick={() => changeTab("Liked")}
                className={`px-4 d-flex justify-content-center align-items-center  ${
                  activeTab === "Liked" ? "tabUnderline" : "text-secondary"
                }`}
              >
                <RiLockFill size={19} className="text-secondary " />
                <p
                  className="profileText cursor-pointer text-secondary mb-0 ms-2"
                  style={{ fontWeight: "500" }}
                >
                  Liked
                </p>
              </div>
            </div>
            <hr className="text-secondary mt-1 mb-2" />
            <div className="d-flex flex-wrap gap-3">
              {activeTab === "Video" &&
                userProfileDetail?.myVideos?.map((video, i) => (
                  <div key={i} className="d-flex flex-column videoContainer">
                    <video
                      className="profileVideos"
                      onClick={() => handleVideoClick(i)}
                      controls={false}
                      muted={true}
                      autoPlay={false}
                    >
                      <source
                        src={
                          "https://iak-media.nyc3.digitaloceanspaces.com/" +
                          video.video
                        }
                      />
                    </video>
                    <p className="mb-0 mt-1 ms-1">{video.caption}</p>
                  </div>
                ))}
              {activeTab === "Liked" &&
                userProfileDetail?.likedVideos?.map((video, i) => (
                  <div key={i} className="d-flex flex-column videoContainer">
                    <video
                      onClick={() => setFullVideo(true)}
                      controls={false}
                      muted={true}
                      autoPlay={false}
                      className="profileVideos"
                    >
                      <source
                        src={
                          "https://iak-media.nyc3.digitaloceanspaces.com/" +
                          video?.video?.video
                        }
                      />
                    </video>
                    <p className="mb-0 mt-1 ms-1">{video?.video?.caption}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default UserDetail;
