import { baseQuery } from "./baseQuery";

export const userApi = baseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => "/user/getProfile",
    }),

    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/user/updateProfile",
        method: "POST",
        body,
      }),
    }),
    getUserProfile: builder.query({
      query: (userId) => `/user/userprofile/${userId}`,
    }),
    getAllUser: builder.query({
      query: () => "/user/accounts",
    }),
    search: builder.query({
      query: (query) => `/user/search?query=${query}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetUserProfileQuery,
  useGetAllUserQuery,
  useSearchQuery,
} = userApi;
