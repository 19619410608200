import React from "react";
import google from "../assets/img/google-play.png";
import apple from "../assets/img/apple.png";
import logo from "../assets/img/logo.png";

const Footer = () => {
  return (
    <>
      <div className="" style={{ background: "black" }}>
        <h4 className="text-light text-center pt-4">Download Now</h4>
        <div className="footer_images py-3 d-flex justify-content-center">
          <img src={google} alt="" />
          <img src={apple} alt="" />
          {/* <img style={{ height: "6.8rem" }} src={amazon} alt="" />
          <img
            className="rounded"
            style={{ height: "3.4rem" }}
            src={download}
            alt=""
          /> */}
        </div>

        <div className="footer_detail d-flex justify-content-around  pt-4 ">
          <div>
            <img src={logo} alt="" className="footer_img" />
          </div>
          <div className="footer_headings text-light d-flex flex-column">
            <h6>Company</h6>
            <span>About</span>
            <span>Instant Awesome Kids</span>
            <span>Newsroom</span>
            <span>Contact</span>
            <span>spanyteDance</span>
          </div>

          <div className="footer_headings text-light d-flex flex-column">
            <h6> Programs</h6>
            <span>Instant Awesome Kids for Good</span>
            <span>Effect House</span>
            <span>Advertise on Instant Awesome Kids</span>
            <span>Instant Awesome Kids Rewards</span>
            <span>Instant Awesome Kids spanrowse</span>
            <span>Instant Awesome Kids Emspaneds</span>
          </div>
          <div className="footer_headings text-light d-flex flex-column">
            <h6> Resources</h6>
            <span>Help Center</span>
            <span>Safety Center</span>
            <span>Effect House</span>
            <span>Creator Portal</span>
            <span>Community Guidelines</span>
            <span>Transparency</span>
            <span>Accessispanility</span>
          </div>
          <div className="footer_headings text-light d-flex flex-column">
            <h6>Legal</h6>
            <span>Children's Privacy Policy</span>
            <span>Terms of Service</span>
          </div>
        </div>
        <div className="p-4 d-flex flex-wrap justify-content-between container">
          <select class="form-select w-25 bg-black text-light" aria-label="">
            <option selected>English</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          <p className="text-muted">© 2023 Instant Awesome Kids</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
