import React from "react";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import logo from "../assets/img/logo.png";
import safety from "../assets/img/safety.png";
import hug from "../assets/img/hug.png";
import laptop from "../assets/img/laptop.png";
import scams from "../assets/img/scams.png";
import bullings from "../assets/img/Topics_Bullying.png";
import covid from "../assets/img/Topics_COVID.png";
import election from "../assets/img/SafetyCenter-Elections.png";
import sucide from "../assets/img/SafetyCenter-Suicide.png";
import wellbeing from "../assets/img/SafetyCenter-Wellbeing.png";
import heart from "../assets/img/heart.png";
import safety2 from "../assets/img/safety.svg";

import ad from "../assets/img/ad.png";
import guide from "../assets/img/Guides_Guardian.png";
import guideSaftey from "../assets/img/Guides_Safety.png";
import guidePrivacy from "../assets/img/Guides_Privacy.png";

import Footer from "./Footer";

const Safety = () => {
  return (
    <>
      {/* navbar */}
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <div className="main_logo ps-4">
            <Link to={"/"}>
              <img src={logo} alt="Instant Awesome Kids" />
            </Link>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Topics
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Guides
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Safety & privacy controls
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Safety partners
                </a>
              </li>
            </ul>
            <form class="d-flex mx-4">
              <FiSearch size={22} className="search_iconSafety" />
              <input
                class="ps-5 px-1 safety_input me-2 "
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </form>
          </div>
        </div>
      </nav>
      {/* navbar end */}

      <div className="safety_img d-flex justify-content-center align-items-center text-center ">
        <div>
          <h1 className="text-light fw-bold ">Safety Center</h1>
        </div>
      </div>

      <div className="moving_text d-flex align-items-center py-4">
        <div className="d-flex align-items-center btn_main">
          <img src={safety} width={50} height={50} alt="" />
          <span className="btn_text">
            Countering hate on Instant Awesome Kids
          </span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={hug} width={50} height={50} alt="" />
          <span className="btn_text"> Substance support</span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={laptop} width={50} height={50} alt="" />
          <span className="btn_text">
            Preventing child sexual abuse on Instant Awesome Kids
          </span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={scams} width={50} height={50} alt="" />
          <span className="btn_text">Scams </span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={bullings} width={70} height={50} alt="" />
          <span className="btn_text"> Bullying prevention</span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={covid} width={55} height={50} alt="" />
          <span className="btn_text">COVID-19 </span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={election} width={55} height={50} alt="" />
          <span className="btn_text">Election integrity </span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={sucide} width={50} height={50} alt="" />
          <span className="btn_text"> Suicide & self-harm</span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={wellbeing} width={60} height={50} alt="" />
          <span className="btn_text">Digital well-being </span>
        </div>
        <div className="d-flex align-items-center btn_main">
          <img src={heart} width={50} height={50} alt="" />
          <span className="btn_text"> Sexual assault resources</span>
          <div />
          <div className="d-flex align-items-center btn_main">
            <img src={safety2} width={50} height={50} alt="" />
            <span className="btn_text">See all </span>
          </div>
        </div>
      </div>
      {/* guide  */}
      <div className="guide d-flex justify-content-center my-5">
        <div className="w-50 guides">
          <h1 className="text-center fw-bold">Guides</h1>
          <p style={{ fontSize: "1.1rem" }}>
            Instant Awesome Kids is a place for creativity and expression, and
            we offer a number of tools and controls to help you manage your
            experience. We recommend checking out the guides below to learn more
            about our approach to safety, privacy, and security on Instant
            Awesome Kids. You'll also find helpful information for parents,
            caregivers, and new users.
          </p>
        </div>
      </div>

      {/* guide-card */}
      <div
        className="guide_cards d-flex flex-wrap justify-content-center gap-4 mt-5"
        style={{ marginBottom: "120px" }}
      >
        <div
          className="px-4 pt-5 rounded text-center"
          style={{ boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)" }}
        >
          <h4 style={{ fontWeight: "600" }}>Ads and Your Data</h4>
          <img
            src={ad}
            width={220}
            height={210}
            alt=""
            style={{ objectFit: "cover" }}
          />
        </div>
        <div
          className="px-4 pt-5 rounded text-center"
          style={{ boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)" }}
        >
          <h4 style={{ fontWeight: "600" }}>Guardian’s Guide</h4>
          <img src={guide} width={220} height={210} alt="" />
        </div>
        <div
          className="px-4 pt-5 rounded text-center"
          style={{ boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)" }}
        >
          <h4 style={{ fontWeight: "600" }}>
            Our Approach to <br /> Safety
          </h4>
          <img src={guideSaftey} width={220} height={220} alt="" />
        </div>
        <div
          className="px-4 pt-5 rounded text-center"
          style={{ boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)" }}
        >
          <h4 style={{ fontWeight: "600" }}>Privacy and Security</h4>
          <img src={guidePrivacy} width={220} height={220} alt="" />
        </div>
      </div>

      {/* safety and privacy */}

      <div
        class="mb-3 container"
        style={{
          maxWidth: "85%px",
          boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)",
        }}
      >
        <div class="d-flex gap-4 safety_privacy">
          <div class="col-md-8 sp_detail">
            <div class="card-body ps-5">
              <h1 className="fw-bold mt-5" style={{ fontSize: "3.4rem" }}>
                Safety & privacy
              </h1>
              <h1 className="fw-bold" style={{ fontSize: "3.4rem" }}>
                {" "}
                controls
              </h1>
              <p class="card-text my-4">
                Creating a welcoming environment where everyone feels safe and
                comfortable is our highest priority. Our app settings help you
                manage your account, content, and privacy settings, including
                who can see, like, or comment on your videos. Learn more about
                the settings that let you control your Instant Awesome Kids
                experience.
              </p>
              <button
                className="btn-primary rounded w-50 mt-4 mb-4"
                style={{ padding: "12px 0" }}
              >
                View controls
              </button>
            </div>
          </div>
          <div class="col-md-4 sp_detail">
            <img
              src="./img/road.jpg"
              class="h-100 img-fluid rounded-end"
              alt="..."
            />
          </div>
        </div>
      </div>

      {/* safety partners */}
      <div className="safety_partners d-flex justify-content-center align-items-center">
        <div className="w-50 d-flex text-center flex-column align-items-center">
          <h1 className="text-light fw-bold" style={{ fontSize: "3rem" }}>
            Safety partners
          </h1>
          <p className="text-light mt-3">
            Instant Awesome Kids works with online safety experts including our
            content advisory councils, non-governmental organizations, and
            industry associations around the world as we strive to build a safe
            platform. Hearing from this diverse set of voices is valuable for
            Instant Awesome Kids when developing products, policies, and
            processes that serve the evolving needs of our growing community.
            <div>
              <button
                tton
                className="btn-primary w-50 rounded mt-5"
                style={{ padding: "12px 0" }}
              >
                Learn more
              </button>
            </div>
          </p>
        </div>
      </div>

      {/* safety updates */}
      <div className="safety_update mb-5" style={{ marginTop: "120px" }}>
        <h1 className="text-center fw-bold mb-4" style={{ fontSize: "3rem" }}>
          Safety updates
        </h1>
        <div className="d-flex flex-wrap  justify-content-center gap-4">
          <div
            class="mt-1 rounded"
            style={{
              width: "20rem",
              boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)",
            }}
          >
            <img
              src="./img/road.jpg"
              height={260}
              class="card-img-top rounded-top"
              alt="..."
            />
            <div class="card-body px-3">
              <h6 class="card-title mt-3">
                Helping creators understand our rules with refreshed Community
                Guidelines
              </h6>
              <p class="card-text mb-4 mt-1">3/20/2023</p>
            </div>
          </div>
          <div
            class="mt-1 rounded"
            style={{
              width: "20rem",
              boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)",
            }}
          >
            <img
              src="./img/road.jpg"
              height={260}
              class="card-img-top rounded-top"
              alt="..."
            />
            <div class="card-body px-3">
              <h6 class="card-title mt-3">
                Helping creators understand our rules with refreshed Community
                Guidelines
              </h6>
              <p class="card-text mb-4 mt-1">3/20/2023</p>
            </div>
          </div>
          <div
            class="mt-1 rounded"
            style={{
              width: "20rem",
              boxShadow: "2px 4px 20px rgba(34, 90, 89, 0.2)",
            }}
          >
            <img
              src="./img/road.jpg"
              height={260}
              class="card-img-top rounded-top"
              alt="..."
            />
            <div class="card-body px-3">
              <h6 class="card-title mt-3">
                Helping creators understand our rules with refreshed Community
                Guidelines
              </h6>
              <p class="card-text mb-4 mt-1">3/20/2023</p>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <p className="link fs-4 text-center mb-5" style={{ cursor: "pointer" }}>
          See all
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Safety;
