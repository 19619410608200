const aboutData = [
  {
    heading: "Welcome",
    video:
      "https://www.tiktok.com/embed/v2/7156254159476428075?lang=en-US&is_copy_url=0",
    text1:
      "Welcome to Instant Awesome Kids! Our mobile application is designed with a mission to promote positivity, creative expression, and a safe online community for children. We understand the importance of creating a platform where young minds can thrive, explore their talents, and connect with like-minded individuals in a secure environment.",
    text2:
      "At Instant Awesome Kids, we believe that technology has the power to empower and inspire young individuals. However, we also recognize the need for robust safety measures and proactive moderation to protect our users from harmful content or negative experiences. That's why we have implemented an advanced AI algorithm that constantly monitors both videos and comments to ensure a safe, comfortable, and highly supportive community",
  },
  {
    heading: "Our Primary Goal",
    video:
      "https://www.tiktok.com/embed/v2/7187619201941572907?lang=en-US&is_copy_url=0",
    text1:
      "Our primary goal is to foster a positive and nurturing atmosphere where children can freely express their creativity and talents. Through our platform, kids can showcase their unique abilities, be it dancing, singing, storytelling, art, or any other form of creative expression. We believe that encouraging and celebrating these talents not only boosts their confidence but also nurtures their imagination and personal growth.",
    text2:
      "Safety is our utmost priority. Our AI algorithm analyzes every video and comment, detecting and flagging any potentially harmful or inappropriate content. This proactive approach allows us to swiftly remove such content and maintain a secure environment where children can explore, learn, and interact with their peers",
  },
  {
    heading: "The Passion",
    video:
      "https://www.tiktok.com/embed/v2/7183234658690239787?lang=en-US&is_copy_url=0",
    text1:
      "We understand that building a supportive community is essential for children to flourish. That's why we encourage positive interactions and kindness among our users. Our platform promotes friendship, collaboration, and constructive feedback to help kids develop healthy relationships and learn from one another in a respectful manner",
    text2:
      "We are passionate about providing a platform that parents can trust. With our commitment to maintaining a safe environment, parents can feel confident knowing that their children are engaging with age-appropriate content and interacting with a caring community.",
  },
  {
    heading: "Instant Awesome Kids",
    video:
      "https://www.tiktok.com/embed/v2/7185274674790665477?lang=en-US&is_copy_url=0",
    text1:
      "Instant Awesome Kids is more than just a mobile application. It's a place where children can explore their passions, express themselves creatively, and build lifelong friendships. Join us on this incredible journey as we inspire the next generation of young minds and foster a community filled with positivity, support, and endless possibilities.",
    text2:
      "Together, let's create an instant and lasting impact on the lives of our awesome kids!",
  },
];

export default aboutData;
