import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import profile from "../assets/img/profile.png";

const ChatUsers = ({ active, onClick }) => {
  return (
    <li
      className={`py-2 px-4 border-bottom cursor-pointer  ${
        active ? "active-chat" : ""
      }`}
      onClick={onClick}
    >
      <div className="d-flex justify-content-between chatUser">
        <div className="d-flex align-items-center">
          <div className="chat-profile">
            <img
              src={profile}
              alt="avatar"
              className="d-flex align-self-center me-3"
              width="60"
              crossOrigin="anonymous"
            />
            <span className="bg-success badge-dot"></span>
          </div>
          <div className="ms-2 pt-1">
            <p className="fw-bold mb-0">Awais K.</p>
            <p className="small text-secondary mb-0">Hi, How are you?</p>
          </div>
        </div>

        <div className="pt-1">
          <p className="small text-secondary mb-1">12:30 PM</p>
          <span className="badge bg-danger rounded-pill float-end">12</span>
        </div>
      </div>
    </li>
  );
};

export default ChatUsers;
