import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import search from "../assets/img/search.svg";
import rocket from "../assets/img/rocket.svg";
import hand from "../assets/img/hand.svg";
import lock from "../assets/img/lock.svg";
import safety from "../assets/img/safety.svg";
import phone from "../assets/img/phone.svg";
import gift from "../assets/img/gift_box.svg";
import bag from "../assets/img/bag.svg";
const Support = () => {
  return (
    <>
      <div className="contact_logo py-2">
        <div className="main_logo align-items-center justify-content-start my-2 ps-4">
          {/* <AiOutlineMenu size={30} className="mx-4" /> */}
          <Link to={"/"}>
            <img src={logo} alt="Instant Awesome Kids" />
          </Link>
        </div>
        <hr />
      </div>

      <div className="support_img d-flex align-items-center justify-content-center text-center">
        <div className="p-2" style={{ width: "34rem" }}>
          <h4 style={{ color: "gray" }}>Help Center</h4>
          <h1 className="support_heading">Hi, how can we help?</h1>
          <input
            type="text"
            className="support_input py-3 px-3 mt-5"
            placeholder="Try Create Account  or delete comment"
          />
        </div>
      </div>

      <div className="support container mt-5" style={{ width: "80vw" }}>
        <div className="d-flex justify-content-between">
          <h1 className="support_heading">Popular articles</h1>
          <img src={search} alt="" />
        </div>
        <div className="d-flex flex-wrap justify-content-between mb-4">
          <div className="support_boxes p-3">
            <div>
              <p className="support_text">
                Getting started Creating an account
              </p>
              <h4>Creating an account</h4>
            </div>
          </div>
          <div className="support_boxes p-3">
            <div>
              <p className="support_text">Getting started For You</p>
              <h4>For You</h4>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-between mb-4">
          <div className="support_boxes p-3">
            <div>
              <p className="support_text">
                Getting started Setting up your profile{" "}
              </p>
              <h4>Setting up your profile</h4>
            </div>
          </div>
          <div className="support_boxes p-3">
            <div>
              <p className="support_text">Log in and troubleshooting Log in </p>
              <h4>Reset password</h4>
            </div>
          </div>
        </div>
        {/* topics */}
        <h1 className="support_heading">Topics</h1>

        <div className="d-flex flex-wrap justify-content-between">
          <div className="support_boxes p-3 mb-4">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="box-heading">Getting started</h4>
                <img src={rocket} alt="" />
              </div>
              <p className="support_text">Creating an account</p>
              <p className="support_text">Setting up your profile</p>
              <p className="support_text">For You</p>
              <p className="support_text">Creating your first video </p>
            </div>
          </div>
          <div className="support_boxes p-3 mb-4">
            <div>
              <div className="d-flex  justify-content-between align-items-center">
                <h4 className="box-heading">Using Instant Awesome Kids</h4>
                <img src={hand} alt="" />
              </div>
              <p className="support_text"> Creating videos</p>
              <p className="support_text">Exploring videos</p>
              <p className="support_text">Messaging and notifications</p>
              <p className="support_text">Followers and Following</p>
              <p className="support_text">Growing your audience </p>
              <p className="support_text">Report a problem</p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between">
          <div className="support_boxes p-3 mb-4">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="box-heading">Account and privacy settings</h4>
                <img src={lock} alt="" />
              </div>
              <p className="support_text"> Account privacy settings</p>
              <p className="support_text">Setting up your profile</p>
              <p className="support_text">Ads and data</p>
              <p className="support_text">Deleting an account </p>
            </div>
          </div>
          <div className="support_boxes p-3 mb-4">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="box-heading"> Safety</h4>
                <img src={safety} alt="" />
              </div>
              <p className="support_text">Report a problem</p>
              <p className="support_text">Account and user safety</p>
              <p className="support_text">Reporting security vulnerabilities</p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between">
          <div className="support_boxes p-3 mb-4">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="box-heading">Log in and troubleshooting</h4>
                <img src={phone} alt="" />
              </div>
              <p className="support_text">Log in</p>
              <p className="support_text">Troubleshooting</p>
            </div>
          </div>
          <div className="support_boxes p-3 mb-4">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="box-heading">
                  Instant Awesome Kids LIVE, Gifts, and wallet
                </h4>
                <img src={gift} alt="" />
              </div>
              <p className="support_text">Instant Awesome Kids LIVE</p>
              <p className="support_text"> Gifts</p>
            </div>
          </div>
        </div>

        <div className="support_boxes p-3 mb-4">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="box-heading">Monetize on Instant Awesome Kids</h4>
              <img src={bag} alt="" />
            </div>
            <p className="support_text">
              Become an advertiser on Instant Awesome Kids
            </p>
            <p className="support_text">
              Instant Awesome Kids monetization and advertising policies
            </p>
            <p className="support_text">
              Instant Awesome Kids Creator Marketplace
            </p>
            <p className="support_text">Instant Awesome Kids Creator Next</p>
            <p className="support_text">
              Instant Awesome Kids Creativity Program Beta
            </p>
            <p className="support_text">Video Gifts on Instant Awesome Kids</p>
            <p className="support_text">Tipping on Instant Awesome Kids</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Support;
