import { createSlice } from "@reduxjs/toolkit";

const authState = {
  isAuthenticated: false,
  tokens: {
    access: {
      token: "",
      expires: "",
    },
    refresh: {
      token: "",
      expires: "",
    },
  },
  user: {
    id: "",
    email: "",
    username: "",
    name: "",
    bio: "",
    passwordResetToken: "",
    profile: "",
    phone: "",
    dob: "",
  },
};

export const authSlice = createSlice({
  name: "authData",
  initialState: authState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.tokens = action.payload.tokens;
      state.user = action.payload.user;
    },
    logout: () => authState,
    updateProfile: (state, action) => {
      state.user.profile = action.payload;
    },
    updateUsername: (state, action) => {
      state.user.username = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { login, logout, updateProfile, updateUsername, updateUser } =
  authSlice.actions;

export default authSlice.reducer;
