import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import Footer from "./Footer";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="contact_logo py-2 privacy_nav">
        <div className="main_logo align-items-center justify-content-start my-2 ps-4">
          <Link to={"/"}>
            <img src={logo} alt="Instant Awesome Kids" />
          </Link>
        </div>
        <hr />
      </div>
      <div className="d-flex">
        <div className="col-4 px-5 mt-5 privacy_left">
          <p className="side_text fw-bold"> What information we collect</p>
          <p className="side_text">How we use your information </p>
          <p className="side_text">How we share your information</p>
          <p className="side_text">Where we store your information</p>
          <p className="side_text">Your rights and choices</p>
          <p className="side_text"> Sensitive and Mature Themes</p>
          <p className="side_text">Integrity and Authenticity</p>
          <p className="side_text">The security of your information</p>
          <p className="side_text">
            Information relating to children and teens
          </p>
          <p className="side_text"> Privacy Policy update</p>
          <p className="side_text"> Contact</p>
          <p className="side_text">
            Supplemental Terms – Jurisdiction
            <br />
            -Specific
          </p>
        </div>
        <div className="privacy_right col-8 mt-5 pe-5">
          <div
            className="btn btn-outline-dark py-1 px-3 mb-2"
            style={{ fontSize: "11px" }}
          >
            Other regions
          </div>
          <h1 className="fs-1 mb-4">Privacy Policy</h1>
          <i className="">Last updated: April 5, 2023</i>
          <p className="my-4">
            Welcome to Instant Awesome Kids. This Privacy Policy applies to
            Instant Awesome Kids services (the “Platform”), which include
            Instant Awesome Kids apps, websites, software and related services
            accessed via any platform or device that link to this Privacy
            Policy. The Platform is provided and controlled by Instant Awesome
            Kids Pte. Ltd., with its registered address at 1 Raffles Quay,
            #26-10, South Tower, Singapore 048583{" "}
          </p>
          <p>
            We are committed to protecting and respecting your privacy. This
            Privacy Policy explains how we collect, use, share, and otherwise
            process the personal information of users, and other individuals in
            connection with our Platform. If you do not agree with this policy,
            you should not use the Platform.
          </p>
          <h3 className="fw-bold my-5">What information we collect</h3>
          <small className="">
            We may collect the following information about you:
          </small>
          <h4 className="my-4">Information You Provide</h4>
          <ul>
            <li>
              <span className="fw-bold"> Your profile information.</span> You
              give us information when you register on the Platform, including
              your username, password, date of birth (where applicable), email
              address and/or telephone number, information you disclose in your
              user profile, and your photograph or profile video.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold"> User content.</span> We process the
              content you generate on the Platform, including photographs,
              audios and videos you upload or create, comments, hashtags,
              feedback, reviews, and livestreams you make, and the associated
              metadata, such as when, where, and by whom the content was created
              (“User Content”). Even if you are not a user, information about
              you may appear in User Content created or published by users on
              the Platform. We collect User Content through pre-loading at the
              time of creation, import, or upload, regardless of whether you
              choose to save or upload that User Content, in order to recommend
              audio options and provide other personalized recommendations. If
              you apply an effect to your User Content, we may collect a version
              of your User Content that does not include the effect.
            </li>
          </ul>
          <ul>
            <li>
              We may access content, including text, images, and video, found in
              your device’s <span className="fw-bold">clipboard, </span> with
              your permission. For example, if you choose to initiate content
              sharing with a third-party platform, or choose to paste content
              from the clipboard into the Platform, we access this information
              stored in your clipboard in order to fulfill your request.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">Purchase information. </span>
              When you make a purchase or payment on or through the Platform,
              including when you buy Instant Awesome Kids Coins or purchase
              goods through our shopping features, we collect information about
              the purchase or payment transaction, such as payment card
              information, billing, delivery, and contact information, and items
              you purchased.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">
                Your phone and social network contacts.
              </span>
              If you choose to sync your phone contacts, we will access and
              collect information such as names, phone numbers, and email
              addresses, and match that information against existing users of
              the Platform. If you choose to share your social network contacts,
              we will collect your public profile information as well as names
              and profiles of your social network contacts.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">Proof of your identity or age. </span>
              We sometimes ask you to provide proof of identity or age in order
              to use certain features, such as livestream or verified accounts,
              or when you apply for a Pro Account, ensure that you are old
              enough to use the Platform, or in other instances where
              verification may be required.
            </li>
          </ul>
          <ul>
            <li>
              Information in correspondence you send to us, including when you
              contact us for support or feedback.
            </li>
          </ul>
          <ul>
            <li>
              Information through surveys, research, promotion, contests,
              marketing campaigns, challenges, competitions or events conducted
              or sponsored by us, in which you participate.
            </li>
          </ul>
          <h3 className="fw-bold my-5">Automatically Collected Information</h3>
          <ul>
            <li>
              <span className="fw-bold">Usage Information. </span>
              We collect information regarding your use of the Platform, e.g.,
              how you engage with the Platform, including how you interact with
              content we show to you, the advertisements you view, videos you
              watch and problems encountered, browsing and search history, the
              content you like, the content you save to ‘My Favourites’, the
              users you follow and how you engage with mutual followers.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">Inferred Information. </span>
              We also infer your attributes, including your interests, gender
              and age range for the purpose of personalising content.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">
                Technical Information we collect about you.{" "}
              </span>
              We collect certain information about the device you use to access
              the Platform, such as your IP address, user agent, mobile carrier,
              time zone settings, identifiers for advertising purposes, model of
              your device, the device system, network type, device IDs, your
              screen resolution and operating system, app and file names and
              types, keystroke patterns or rhythms, battery state, audio
              settings and connected audio devices. Where you log-in from
              multiple devices, we will be able to use your profile information
              to identify your activity across devices. We may also associate
              you with information collected from devices other than those you
              use to log-in to the Platform.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">Location Information.</span>
              We collect information about your approximate location, including
              location information based on your SIM card and/or IP address.
              With your permission, we may also collect precise location data
              (such as GPS). In addition, we collect location information (such
              as tourist attractions, shops, or other points of interest) if you
              choose to add location information to your User Content.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">Image and Audio Information. </span>
              We may collect information about the videos, images and audio that
              are a part of your User Content, such as identifying the objects
              and scenery that appear, the existence and location within an
              image of face and body features and attributes, the nature of the
              audio, and the text of the words spoken in your User Content. We
              may collect this information to enable special video effects, for
              content moderation, for demographic classification, for content
              and ad recommendations, and for other non-personally-identifying
              operations.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold"> Cookies. </span>
              We and our service providers and business partners use cookies and
              other similar technologies (e.g., web beacons, flash cookies,
              etc.) (“Cookies”) to automatically collect information, measure
              and analyze how you use the Platform, including which pages you
              view most often and how you interact with content, enhance your
              experience using the Platform, improve the Platform, provide you
              with advertising, and measure the effectiveness of advertisements
              and other content. We and our partners also use Cookies to promote
              the Platform on other platforms and websites. Cookies enable the
              Platform to provide certain features and functionality. Web
              beacons are very small images or small pieces of data embedded in
              images, also known as “pixel tags” or “clear GIFs,” that can
              recognize Cookies, the time and date a page is viewed, a
              description of the page where the pixel tag is placed, and similar
              information from your computer or device. To learn how to disable
              certain Cookies, see the “Your rights and choices” section below.
            </li>
          </ul>
          <h3 className="fw-bold my-5">Information From Other Sources</h3>
          <small>
            We may receive the information described in this Privacy Policy from
            other sources, such as:
          </small>
          <ul className="mt-3">
            <li>
              If you choose to register or use the Platform using a third-party
              social network account details (e.g., Facebook, Twitter,
              Instagram, Google) or login service, you will provide us or allow
              to provide us with your username, public profile, and other
              possible information related to such account. We will likewise
              share certain information with your social network such as your
              app ID, access token and the referring URL. If you link your
              Instant Awesome Kids account to another service, we may receive
              information about your use of that service.
            </li>
          </ul>
          <ul>
            <li>
              Advertisers, measurement and other partners share information with
              us about you and the actions you have taken outside of the
              Platform, such as your activities on other websites and apps or in
              stores, including the products or services you purchased, online
              or in person. These partners also share information with us, such
              as mobile identifiers for advertising, hashed email addresses and
              phone numbers, and cookie identifiers, which we use to help match
              you and your actions outside of the Platform with your Instant
              Awesome Kids account. Some of our advertisers and other partners
              enable us to collect similar information directly from their
              websites or apps by integrating our Instant Awesome Kids
              Advertiser Tools (such as Instant Awesome Kids Pixel).
            </li>
          </ul>
          <ul>
            <li>
              We may obtain information about you from certain affiliated
              entities within our corporate group, including about your
              activities on their platforms.
            </li>
          </ul>
          <ul>
            <li>
              We may receive information about you from others, including where
              you are included or mentioned in User Content, direct messages, in
              a complaint, appeal, request or feedback submitted to us, or if
              your contact information is provided to us. We may collect
              information about you from other publicly available sources.
            </li>
          </ul>
          <ul>
            <li>
              We may receive information from merchants and payment and
              transaction fulfillment providers about you, such as payment
              confirmation details, and information about the delivery of
              products you have purchased through our shopping features.
            </li>
          </ul>
          <hr className="mt-5" />
          <h3 className="fw-bold my-5">How we use your information</h3>
          <p>
            As explained below, we use your information to improve, support and
            administer the Platform, to allow you to use its functionalities,
            and to fulfill and enforce our Terms of Service. We may also use
            your information to, among other things, personalise content you see
            on the Platform, promote the Platform, and customize your ad
            experience. We generally use the information we collect in the
            following ways:
          </p>
          <ul>
            <li>
              To fulfill requests for products, services, Platform
              functionality, support and information for internal operations,
              including troubleshooting, data analysis, testing, research,
              statistical, and survey purposes and to solicit your feedback.
            </li>
          </ul>
          <ul>
            <li>
              To provide our shopping features and facilitate the purchase and
              delivery of products, goods and services, including sharing your
              information with merchants, payment and transaction fulfillment
              providers, and other service providers in order to process your
              orders.
            </li>
          </ul>
          <ul>
            <li>
              To personalise the content you see when you use the Platform. For
              example, we may provide you with services based on the country
              settings you have chosen or show you content that is similar to
              content that you have liked or interacted with.
            </li>
          </ul>
          <ul>
            <li>
              To send promotional materials, including by instant messaging or
              email, from us or on behalf of our affiliates and trusted third
              parties.
            </li>
          </ul>
          <ul>
            <li>
              To improve and develop our Platform and conduct product
              development.
            </li>
          </ul>
          <ul>
            <li>
              To measure and understand the effectiveness of the advertisements
              and other content we serve to you and others, and to deliver
              advertising, including targeted advertising, to you on the
              Platform.
            </li>
          </ul>
          <ul>
            <li>
              To support the social functions of the Platform, including to
              permit you and others to connect with each other (for example,
              through our Find Friends function) and to share whether you are
              active on the Platform (and other information which you choose to
              share) with your friends, to provide our messaging service if you
              choose to use this function, to suggest accounts to you and
              others, and for you and others to share, download, and otherwise
              interact with User Content posted through the Platform.
            </li>
          </ul>
          <ul>
            <li>To enable you to participate in the virtual items program.</li>
          </ul>
          <ul>
            <li>
              To allow you to participate in interactive features of the
              Platform, such as enabling your content to be used in other users’
              videos.
            </li>
          </ul>
          <ul>
            <li>
              To use User Content as part of our advertising and marketing
              campaigns to promote the Platform, to invite you to participate in
              an event, and to promote popular topics, hashtags and campaigns on
              the Platform.
            </li>
          </ul>
          <ul>
            <li>
              To understand how you use the Platform, including across your
              devices.
            </li>
          </ul>
          <ul>
            <li>
              To infer additional information about you, such as your age range,
              gender, and interests.
            </li>
          </ul>
          <ul>
            <li>
              To help us detect and combat abuse, harmful activity, fraud, spam,
              and illegal activity on the Platform.
            </li>
          </ul>
          <ul>
            <li>
              To ensure content is presented in the most effective manner for
              you and your device.
            </li>
          </ul>
          <ul>
            <li>
              To promote the safety, security of the Platform, including by
              scanning, analyzing, and reviewing User Content, messages and
              associated metadata for violations of our Terms of Service,
              Community Guidelines, or other conditions and policies.
            </li>
          </ul>
          <ul>
            <li>
              To facilitate research conducted by independent researchers that
              meets certain criteria.
            </li>
          </ul>
          <ul>
            <li>To verify your identity or age.</li>
          </ul>
          <ul>
            <li>
              To communicate with you, including to notify you about changes in
              our services.
            </li>
          </ul>
          <ul>
            <li>
              To announce you as a winner of our contests or promotions if
              permitted by the promotion rule, and to send you any applicable
              prizes.
            </li>
          </ul>
          <ul>
            <li>
              To enforce our Terms of Service, Community Guidelines, and other
              conditions and policies.
            </li>
          </ul>
          <ul>
            <li>
              To enforce our Terms of Service, Community Guidelines, and other
              conditions and policies.
            </li>
          </ul>
          <ul>
            <li>
              To train and improve our technology, such as our machine learning
              models and algorithms.
            </li>
          </ul>
          <ul>
            <li>
              To facilitate and fulfill sales, promotion, and purchases of goods
              and services and to provide user support.
            </li>
          </ul>
          <hr className="mt-5" />
          <h3 className="my-5 fw-bold">How we share your information</h3>
          <small>We share your information with the following parties:</small>
          <h3 className="my-4 fw-bold">Business Partners</h3>
          <p>
            If you choose to register to use the Platform using your social
            network account details (e.g., Facebook, Twitter, Instagram,
            Google), you will provide us or allow your social network to provide
            us with your phone number, email address, username and public
            profile. We will likewise share certain information with the
            relevant social network such as your app ID, access token and the
            referring URL. If you choose to allow a third-party service to
            access your account, we will share certain information about you
            with the third party. Depending on the permissions you grant, the
            third party may be able to obtain your account information and other
            information you choose to provide.
          </p>
          <p>
            Where you opt to share content on social media platforms, the video,
            username and accompanying text will be shared on that platform or,
            in the case of sharing via instant messaging platforms such as
            Whatsapp, a link to the content will be shared.
          </p>
          <h3 className="my-4 fw-bold"> Service Providers</h3>
          <p>
            We provide information and content to service providers who support
            our business, such as cloud service providers and providers of
            content moderation services to ensure that the Platform is a safe
            and enjoyable place and service providers that assist us in
            marketing the Platform.
          </p>
          <ul>
            <li>
              <span className="fw-bold">
                Payment processors and transaction fulfillment providers:
              </span>
              If you choose to buy Coins or conduct other payment related
              transactions, we will share data with the relevant payment
              provider to facilitate this transaction. For Coin transactions, we
              share a transaction ID to enable us to identify you and credit
              your account with the correct value in coins once you have made
              the payment.
            </li>
          </ul>
          <ul>
            <li>
              <span className="fw-bold">Analytics providers:</span>
              We use analytics providers to help us in the optimisation and
              improvement of the Platform. Our third-party analytics providers
              also help us serve targeted advertisements.
            </li>
          </ul>
          <h3 className="my-4 fw-bold">
            Advertisers, Advertising Networks and Measurement Partners
          </h3>
          <p>
            We share information with advertisers and third-party measurement
            companies to show how many and which users of the Platform have
            viewed or clicked on an advertisement.
          </p>
          <p>
            If you use the Instant Awesome Kids Lite version of Instant Awesome
            Kids, we share information with advertising networks to display
            personalised advertisements to you on the Instant Awesome Kids Lite
            app and elsewhere online. We are not responsible for the privacy
            practices of these third parties, and the information practices of
            these third parties are not covered by this Privacy Policy.
          </p>
          <h3 className="fw-bold my-4">Independent Researchers</h3>
          <p>
            We share your information with independent researchers to facilitate
            research that meets certain criteria.
          </p>
          <h3 className="fw-bold my-4">Our Corporate Group</h3>
          <p>
            We may also share your information with other members, subsidiaries,
            or affiliates of our corporate group, including to provide the
            Platform, to improve and optimise the Platform, to prevent illegal
            use and to support users.
          </p>
          <h3 className="fw-bold my-4"> For Legal Reasons</h3>
          <p>
            We will share your information with law enforcement agencies, public
            authorities or other organisations if legally required to do so, or
            if such use is reasonably necessary to:
          </p>
          <ul>
            <li>comply with legal obligation, process or request;</li>
          </ul>
          <ul>
            <li>
              enforce our Terms of Service and other agreements, policies, and
              standards, including investigation of any potential violation
              thereof;
            </li>
          </ul>
          <ul>
            <li>
              detect, prevent or otherwise address security, fraud or technical
              issues; or
            </li>
          </ul>
          <ul>
            <li>
              protect the rights, property or safety of us, our users, a third
              party or the public as required or permitted by law (including
              exchanging information with other companies and organisations for
              the purposes of fraud protection and credit risk reduction).
            </li>
          </ul>
          <h3 className="fw-bold my-4">Public Profiles</h3>
          <p>
            Please note that if your profile is public, your content will be
            visible to anyone on the Platform and may also be accessed or shared
            by your friends and followers as well as third parties such as
            search engines, content aggregators and news sites. You can change
            who can see a video each time you upload a video. Alternatively, you
            can change your profile to default private by changing your settings
            to 'Private Account' in “Manage my account” settings.
          </p>
          <h3 className="fw-bold my-4">
            Sale, Merger or Other Business Transactions
          </h3>
          <p>We may also disclose your information to third parties:</p>
          <ul>
            <li>
              in the event that we sell or buy any business or assets (whether a
              result of liquidation, bankruptcy or otherwise), in which case we
              will disclose your data to the prospective seller or buyer of such
              business or assets; or
            </li>
          </ul>
          <ul>
            <li>
              if we sell, buy, merge, are acquired by, or partner with other
              companies or businesses, or sell some or all of our assets. In
              such transactions, user information may be among the transferred
              assets.
            </li>
          </ul>
          <h3 className="fw-bold my-4">
            Merchants, Payment and Transaction Fulfillment Providers, and Other
            Service Providers
          </h3>
          <p>
            When you make a purchase through our shopping features, we share the
            information related to the transaction with the merchant, payment
            and transaction fulfillment providers, and other service providers.
            For example, we will share the order items, contact details and
            delivery information so your order can be processed. These entities
            may use the information shared in accordance with their privacy
            policies.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5"> Where we store your information</h3>
          <p>
            Your information may be stored on servers located outside the
            country where you live, such as in Singapore, Malaysia or the United
            States. We maintain major servers around the world to bring you our
            services globally and continuously.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5">Your rights and choices</h3>
          <p>
            You have rights and choices when it comes to your information. You
            may be afforded certain rights under applicable laws, which may
            include the right to access, delete, update, or rectify your data,
            to be informed of the processing of your data, to file complaints
            with authorities, and potentially other rights. You may submit a
            request to exercise your rights under applicable laws at
            https://www.InstantAwesomeKids.com/legal/report/privacy. You may
            appeal any decision we have made about your request by following the
            instructions in the communication you receive from us notifying you
            of our decision. Please also see the Supplemental Terms below on
            whether a local representative or local contact is available for
            your country.
          </p>
          <p>
            You can access and edit most of your profile information by signing
            into Instant Awesome Kids. You can delete the User Content you
            uploaded. We also provide a number of tools in Settings that allow
            you to control, among others, who can view your videos, send you
            messages, or post comments to your videos. Should you choose to do
            so, you may delete your entire account in Settings.
          </p>
          <p>
            You may be able to refuse or disable Cookies by adjusting your
            device browser settings. Because each browser is different, please
            consult the instructions provided by your browser. Please note that
            you may need to take additional steps to refuse or disable certain
            types of Cookies. For example, due to differences in how browsers
            and mobile apps function, you may need to take different steps to
            opt out of Cookies used for targeted advertising in a browser and to
            opt out of targeted advertising for a mobile application, which you
            may control through your device settings or mobile app permissions.
            In addition, your opt-out selection is specific to the particular
            browser or device that you are using when you opt out, so you may
            need to opt-out separately for each of browser or device. If you
            choose to refuse, disable, or delete Cookies, some of the
            functionality of the Platform may no longer be available to you.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5"> The security of your information</h3>
          <p>
            We take steps to ensure that your information is treated securely
            and in accordance with this policy. Unfortunately, the transmission
            of information via the internet is not completely secure. Although
            we will use reasonable measures to protect your personal data, for
            example, by encryption, we cannot guarantee the security of your
            information transmitted via the Platform; any transmission is at
            your own risk.
          </p>
          <p>
            We have appropriate technical and organizational measures to ensure
            a level of security appropriate to the risk of varying likelihood
            and severity for the rights and freedoms of you and other users. We
            maintain these technical and organizational measures and will amend
            them from time to time to improve the overall security of our
            systems.
          </p>
          <p>
            We will, from time to time, include links to and from the websites
            of our partner networks, advertisers and affiliates. If you follow a
            link to any of these websites, please note that these websites have
            their own privacy policies and that we do not accept any
            responsibility or liability for these policies. Please check these
            policies before you submit any information to these websites.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5"> How long we keep your information</h3>
          <p>
            We retain information for as long as necessary to provide the
            Platform and for the other purposes set out in this Privacy Policy.
            We also retain information when necessary to comply with contractual
            and legal obligations, when we have a legitimate business interest
            to do so (such as improving and developing the Platform, and
            enhancing its safety, security and stability), and for the exercise
            or defence of legal claims.
          </p>
          <p>
            The retention periods are different depending on different criteria,
            such as the type of information and the purposes for which we use
            the information. For example, when we process your information such
            as your profile information to provide you with the Platform, we
            keep this information for as long as you have an account. If you
            violate our Terms of Service, Community Guidelines, or other
            conditions or policies, we may remove your profile and User Content
            from public view immediately, but may keep other information about
            you to process the violation.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5">
            Information relating to children and teens
          </h3>
          <p>
            Instant Awesome Kids is not directed at children under the age of
            13. In certain cases this age may be higher due to local regulatory
            requirements, please see your local supplemental terms for more
            information. If you believe that there is a user who is below this
            minimum age, please contact us at
            https://www.InstantAwesomeKids.com/legal/report/privacy.
          </p>
          <p>
            If you are a parent or guardian, our Guardian's Guide contains
            information and resources to help you understand the Platform and
            the tools and controls you can use.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5">Privacy Policy update</h3>
          <p>
            We may update this Privacy Policy from time to time. When we update
            the Privacy Policy, we will notify you by updating the “Last
            Updated” date at the top of this policy and posting the new Privacy
            Policy or providing any other notice required by applicable law.
            Your continued access to or use of the Platform after the date of
            the updated policy constitutes your acceptance of the updated
            policy. If you do not agree to the updated policy, you must stop
            accessing or using the Platform.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5"> Contact</h3>
          <p>
            If you have questions, comments, complaints or requests regarding
            this Privacy Policy, please contact us at:
            https://www.InstantAwesomeKids.com/legal/report/privacy
          </p>
          <p>
            Please also see the supplemental terms below on whether a local
            representative or local contact is available for your country.
          </p>
          <p>
            We will endeavour to deal with your request as soon as possible.
            This is without prejudice to your right to make a complaint with a
            relevant data protection authority, where applicable.
          </p>
          <hr className="my-5" />
          <h3 className="fw-bold my-5">
            Supplemental Terms – Jurisdiction-Specific
          </h3>
          <p>
            In the event of a conflict between the provisions of the
            Supplemental Terms – Jurisdiction-Specific that are relevant to your
            jurisdiction from which you access or use the services, and the rest
            of policy, the relevant jurisdictions’ Supplemental Terms –
            Jurisdiction-Specific will supersede and control.
          </p>
          <h3 className="fw-bold my-4">Australia</h3>
          <p>
            If you are using the Platform in Australia, the following additional
            terms shall apply.
          </p>
          <p>
            Certain entities in our corporate group, located outside of
            Australia, are given limited remote access to your information so
            that they can provide certain functions, as described in the section
            on "How we share your information". Please see here for further
            information on the countries in which our corporate group entities
            which receive personal information from Australia are located.
          </p>
          <h3 className="fw-bold my-4">Brazil</h3>
          <p>
            If you are using the Platform in Brazil, the following additional
            terms apply. If any conflict arises between the main Privacy Policy
            and the additional terms, the following terms shall prevail:
          </p>
          <p>
            Exercise of data protection rights. Brazilian law provides certain
            rights to individuals with regard to their personal data. Thus, we
            seek to ensure transparency and access controls in order to allow
            users to benefit from the mentioned rights.
          </p>
          <p>
            We will respond and/or fulfill your requests for the exercise of
            your rights below, according to the applicable law and when
            applicable, to the Brazilian General Data Protection Law - LGPD:
          </p>
          I. confirmation of whether your data are being processed;
          <p> II. access to your data;</p>
          <p> III. correction of incomplete, inaccurate or outdated data;</p>
          <p>IV. anonymization, blocking or erasure of data;</p>
          <p> V. portability of personal data to a third party;</p>
          <p>VI. object to the processing of personal data;</p>
          <p>
            VII. information of public and private entities with which we shared
            data;
          </p>
          <p>
            VIII. information about the possibility to refuse providing personal
            data and the respective consequences, when applicable;
          </p>
          <p> IX. withdrawal of your consent.</p>
          <p>
            X. request a review of decisions made solely based on automated
            processing of personal data affecting your interests, including
            decisions made to define your personal, professional, consumer or
            credit profile, or aspects of your personality.
          </p>
          <p>
            We encourage you to contact us if you are not satisfied with how we
            have responded to any of your rights requests. You also have the
            right to lodge a complaint with the Brazilian Data Protection
            Authority (ANPD).
          </p>
          <p>
            Verifying your identity: For your safety and to allow us to make
            sure that we do not disclose any of your personal data to
            unauthorized third parties, in order to verify your identity and
            guarantee the adequate exercise of your rights, we may request
            specific information and/or documents from you before we can
            properly respond to a request received concerning your data. All
            data and documents received from you in the process of responding to
            your requests will be used for the strict purposes of analyzing your
            request, authenticating your identity, and finally responding to
            your request.
          </p>
          <p>
            Limitations to your rights: In certain situations, we may have
            legitimate reasons not to comply with some of your requests. For
            instance, we may choose not to disclose certain information to you
            when a disclosure could adversely impact our business whenever there
            is a risk of violation to our trade secrets or intellectual property
            rights. In addition, we may refrain from complying with a request
            for erasure when the maintenance of your data is required for
            complying with legal or regulatory obligations or when such
            maintenance is required to protect our rights and interests in case
            a dispute arises. Whenever this is the case and we are unable to
            comply with a request you make, we will let you know the reasons why
            we cannot fulfill your request.
          </p>
          <p>
            Contact: In case of doubt about your privacy, your rights or how to
            exercise them, please contact us through the form "Contact". If you
            have any questions about the processing of your personal data, we
            would like to clarify them.
          </p>
          <p>
            DPO: If you wish to reach the Instant Awesome Kids's Data Protection
            Officer, contact us at:
            https://www.InstantAwesomeKids.com/legal/report/DPO
          </p>
          <p>
            Access Logs. We keep your application access logs, under
            confidentiality, in a controlled and safe environment for at least 6
            months, in order to comply with legal obligations.
          </p>
          <p>
            Language. The Policy may have been prepared in the English language
            and in the Portuguese language. If you are a user located in Brazil,
            you shall refer to the Portuguese version, which shall prevail.
          </p>
          <p>
            International Transfers. International data transfers are necessary
            for us to provide our services. If you are located in Brazil, we
            will always rely on one of the international data transfer
            mechanisms under applicable data protection laws and regulations.
          </p>
          <h3 className="fw-bold my-4">Canada</h3>
          <p>
            If you are using the Platform in Canada, the following additional
            terms apply.
          </p>
          <p>
            Your Rights. Subject to limited exceptions under applicable law, you
            have the right to access, update, rectify and correct inaccuracies
            in your personal information in our custody and control and to
            withdraw your consent to our collection, use and disclosure of your
            personal information. To exercise these rights, you may contact us
            using the contact information set out below. We may require certain
            personal information for the purpose of verifying the identity of
            the individual making the request.
          </p>
          <p>
            Your Choices. You can unsubscribe from our marketing emails at any
            time by clicking the “unsubscribe” link included at the bottom of
            each email we send. You can also adjust your preferences regarding
            certain types of personalized advertising by using the Ads tools in
            Settings.
          </p>
          <p>
            Transfer Outside Your Jurisdiction. We and our service providers
            (including members, subsidiaries, or affiliates of our corporate
            group) may access, store and otherwise process personal information
            outside of Canada (and, for residents of Quebec, outside of Quebec),
            including in the United States, Malaysia, Singapore, and other
            foreign jurisdictions where we or our service providers are located.
            For information about the manner in which we or our service
            providers (including service providers outside Canada) process
            personal data, please contact us using the contact information set
            out below.
          </p>
          <p>
            Contact. If you are a resident of Canada, you can contact our
            Privacy Office at
            https://www.InstantAwesomeKids.com/legal/report/Privacy-Office-Canada
          </p>
          <h3 className="fw-bold my-4">Egypt</h3>
          <p>
            If you are using our services in Egypt, the following additional
            terms apply.
          </p>
          <ul>
            <li>
              You declare that you are over the age of 18. If you are under the
              age of 18, please have your parent or legal guardian read this
              with you. If you are reviewing these terms as the parent/legal
              guardian of a user who is under the age of 18, you hereby declare
              that such user is above the age of 13 and that you have read and
              acknowledged Instant Awesome Kids's Privacy Policy and Terms of
              Use and agree to the use by your child of the Platform and
              registration for an account.
            </li>
          </ul>
          <h3 className="fw-bold my-4">India</h3>
          <p>
            If you are using our services in India, the Platform is provided and
            controlled by ByteDance (India) Technology Private Limited. Instant
            Awesome Kids is our brand for providing and promoting the services.
            When using these services from India, please accordingly read
            “Instant Awesome Kids”, “we” or “us” in this policy to refer to
            Bytedance (India) Technology India Private Limited.
          </p>
          <h3 className="fw-bold my-4">Indonesia</h3>
          <p>
            If you are using our services in Indonesia, the following additional
            terms apply. In the event of any conflict between the following
            additional terms and the provisions of the main body of this policy,
            the following terms shall apply.
          </p>
          <p>
            Age, Parental and Guardian Consent. By accessing and/or using this
            Platform, you represent that you are at least 21 years of age or
            married or not under guardianship. If you are below 21 years old and
            you are not married, or under guardianship:
          </p>
          <ul>
            <li>
              you must obtain approval from your parent(s) or legal guardian(s);
              and
            </li>
          </ul>
          <ul>
            <li>
              your parent(s) or legal guardian(s) are responsible for: (i) all
              your actions in connection with your access to and use of the
              Platform; (ii) your compliance with this policy; and (iii)
              ensuring that any of your participation in the Platform will not,
              in any event, result in any violation of applicable laws and
              regulations relating to child protections.
            </li>
          </ul>
          <p>
            If you do not have consent from your parent(s) or legal guardian(s)
            and your parent(s) or guardian(s) is not willing to open the account
            under their name, you must cease accessing the Platform.
          </p>
          <p>
            Your Choices. You may withdraw your consent to Instant Awesome
            Kids's disclosure of personal data to third parties. Upon your
            request, we will cease to display, publish, transmit, disseminate,
            and/or open the access to your personal data to third parties.
            Please note that by withdrawing your consent to the disclosure
            and/or collection of your personal data, we may not be able to
            fulfill your requests and you may not be able to use some of Instant
            Awesome Kids features and functionality.
          </p>
          <p>
            You may request Instant Awesome Kids to (i) disclose the history of
            personal data that we have collected; and/or (ii) erase and dispose
            of your personal data that we have collected on our server. Please
            note that by requesting us to erase and dispose of your personal
            data, you may not be able to use some of Instant Awesome Kids’s
            features and functionality.
          </p>
          <p>
            To exercise any of your rights, contact us at:
            https://www.InstantAwesome Kids.com/legal/report/privacy.
          </p>
          <p>
            Notification. In the event of any breach of personal data, we will
            notify you and provide you with information regarding such breach of
            personal data.
          </p>
          <p>
            Data retention. We retain your information for as long as it is
            necessary to provide you with the service. Where we do not need your
            information in order to provide the service to you, we retain it
            only for so long as we have a legitimate business purpose in keeping
            such data. However, there are occasions where we are likely to keep
            this data for five (5) years (or longer if required) in accordance
            with our legal obligations or where it is necessary for the
            establishment, exercise or defence of legal claims.
          </p>
          <p>
            After you have terminated your use of our Platform and the five (5)
            years retention period has lapsed, we store your information in an
            aggregated and anonymised format. Non-personally identifiable
            information may be retained indefinitely for analytics.
          </p>
          <h3 className="fw-bold my-4">Israel</h3>
          <p>
            If you are using our services in Israel, the following additional
            terms apply.
          </p>
          <ul>
            <li>
              You declare that you are over the age of 18. If you are under the
              age of 18, please have your parent or legal guardian read this
              with you. If you are reviewing these terms as the parent/legal
              guardian of a user who is under the age of 18, you hereby declare
              that such user is above the age of 13 and that you have read and
              acknowledged Instant Awesome Kids's Privacy Policy and Terms of
              Use and agree to the use by your child of the Platform and
              registration for an account.
            </li>
          </ul>
          <h3 className="fw-bold my-4">Mexico</h3>
          <p>
            If you are using our services in Mexico, the following additional
            terms apply. In the event of any conflict between the following
            additional terms and the provisions of the main body of this policy,
            the following terms shall prevail.
          </p>
          <p>How we use your personal data.</p>
          <p>
            Almost all purposes of processing stated in this section of the main
            Privacy Policy are necessary purposes. However, the following are
            secondary purposes:
          </p>
          <ul>
            <li>Provide you with personalized advertising.</li>
          </ul>
          <p>
            The above purposes are not necessary for us to provide the Platform
            but allows us to provide you with a better experience. We offer you
            the possibility to take decisions regarding how we use and disclose
            your personal data. You can always limit the secondary purposes of
            personalised advertising by using the tools in Settings that allow
            you to limit certain functions.
          </p>
          <p>We use both human and automated means to process your data.</p>
          <p>How we share your personal data.</p>
          <p>
            We may share your personal data to a third party for purposes other
            than processing on behalf of us. We will obtain your consent for
            such sharing as required by applicable laws.
          </p>
          <p>
            By providing us with your personal data and using our Platform you
            agree to the transfers that require your consent. You can always
            revoke your consent and exercise your rights, as stated below.
          </p>
          <p>Your Rights.</p>
          <p>
            You have the following rights with respect to your personal data:
            access, rectification, cancellation, opposition, consent withdrawal,
            limitation to the use and disclosure of your data. You can exercise
            your rights by sending your request to
            https://www.InstantAwesomeKids.com/legal/report/privacy. To know
            more about the applicable requirements and procedure to exercise
            your rights, contact us to the mentioned email address. If you are
            under 18 years, you may exercise your rights through a parent or
            guardian. Your rights requests will be resolved as soon as possible
            according to the nature of your request.
          </p>
          <p>Information relating to children.</p>
          <p>
            You may review our parental consent section in the Terms of Service.
          </p>
          <h3 className="fw-bold my-4">Philippines</h3>
          <p>
            If you are using our services in the Philippines, the following
            additional terms shall apply pursuant to the relevant laws, rules
            and regulations and issuances by the Philippines National Privacy
            Commission (“NPC”) on data privacy.
          </p>
          <p>
            Your Rights. To the extent that the relevant laws, rules and
            regulations on data privacy recognize your rights and freedoms as
            data subjects, you shall have the right to information, object,
            access, rectification, erasure or blocking, lodge complaints before
            the NPC, damages and data portability. Before we can respond to a
            request to exercise one or more of the rights listed above, you may
            be required to verify your identity or your account details.
          </p>
          <h3 className="fw-bold my-4">Russia</h3>
          <p>
            What are legal grounds for data processing. When we process your
            personal data, we rely on the grounds of your consent, performance
            of a contract, our legitimate interest and obligations to process
            personal data, or when we are so required by law.
          </p>
          <p>
            By signing up and using the Platform, you consent to the processing
            of personal data in accordance with this Privacy Policy.
          </p>
          <p>
            Where we store your personal data. Your personal data may be
            transferred from Russia to Singapore and stored in that location,
            outside of the country where you live.
          </p>
          <p>
            Public profiles. We rely on your consent to public distribution of
            personal data, when your account settings are 'Public Profile'. You
            can change your profile to private by changing your settings to
            'Private Account' in “Manage my account” settings.
          </p>
          <h3 className="fw-bold my-4">South Africa</h3>
          <p>
            If you are using our services in South Africa, the following
            additional terms apply.
          </p>
          <p>
            You declare that you are over the age of 18. If you are under the
            age of 18, please have your parent or legal guardian read this with
            you. If you are reviewing these terms as the parent/legal guardian
            of a user who is under the age of 18, you hereby declare that such
            user is above the age of 13 and that you have read and acknowledged
            Instant Awesome Kids's Privacy Policy and Terms of Use and agree to
            the use by your child of the Platform and registration for an
            account.
          </p>
          <h3 className="fw-bold my-4">South Korea</h3>
          <p>
            If you are using our services in South Korea, the following
            additional terms apply. In the event of any conflict between the
            following additional terms and the provisions of the main body of
            this policy, the following terms shall prevail.
          </p>
          <ul>
            <li>
              How we share your personal data. In addition to the main text of
              this policy, in the event we use information such as
              user-generated content and video content on our Platform as part
              of our advertising and marketing campaigns to promote the
              Platform, your personal data contained in such information may be
              disclosed to the recipients of such advertising or marketing
              content.
            </li>
          </ul>
          <ul>
            <li>
              Customized Ads. We may from time to time provide customized ads on
              our Platform to provide you our Platform for free.
            </li>
          </ul>
          <ul>
            <li>
              Data retention. We destroy personal data whose purpose of
              collection as consented to by you have been achieved, or whose
              periods of and use to which you consented to or which were
              provided in this privacy policy have expired; provided, however,
              we will continue to store your personal data for the following
              statutorily-prescribed periods, where applicable, including, but
              not limited to:
            </li>
          </ul>
          <ul>
            <li>
              Act on Consumer Protection in Electronic CommerceRecords on your
              cancellation of an order, your payment on a purchased item, and
              our supply of a good/service: 5 yearsRecords on the handling of
              consumer complaints or disputes: 3 yearsRecords on advertisements
              and labels: 6 months
            </li>
          </ul>
          <ul>
            <li>
              Protection of Communications Secrets Act Records on your visits to
              our website: 3 months
            </li>
          </ul>
          <ul>
            <li>
              Protection of Communications Secrets Act Records on your visits to
              our website: 3 months
            </li>
          </ul>
          <ul>
            <li>
              Destruction of Personal data. We destroy your personal data in a
              manner that renders it unrestorable by the relevant department.
            </li>
          </ul>
          <ul>
            <li>
              Data rights. You have the right to access personal data we hold
              about you, to rectify any personal data held about you that is
              inaccurate, to request the deletion of personal data held about
              you, and the right to request the suspension of the processing of
              your personal data. You can exercise your rights by contacting us
              at https://www.InstantAwesomeKids.com/legal/report/privacy.
            </li>
          </ul>
          <ul>
            <li>
              Data Security. We work hard to protect Instant Awesome Kids and
              our users from unauthorized access to or unauthorized alteration,
              disclosure or destruction of information we hold. To this end, we
              have in place technical, managerial and physical safeguards,
              including internal policy for data protection, limiting the access
              to personal data on a need-to-know basis and controlling access to
              the facilities where personal data is processed.
            </li>
          </ul>
          <ul>
            <li>
              Information Relating to Children. Instant Awesome Kids is not
              directed at children under the age of 14.
            </li>
          </ul>
          <ul>
            <li>
              Entrustment and/or Overseas Transfer of Personal Data. We entrust
              your data to our affiliates, cloud storage providers, IT service
              providers, and data centers, some of whom are located abroad (See
              the Korean language Privacy Policy), subject to your consents or
              notifications to you, if applicable. The entities receiving and
              processing your data are committed to using and storing personal
              data in compliance with domestic and international regulations and
              to taking all available physical and technical measures to protect
              personal data. You may opt-out of such transfer so long as the
              transfer is not necessary to provide you with the Service, by
              contacting us here.
            </li>
          </ul>
          <ul>
            <li>
              Local representative pursuant to the Personal Information
              Protection Act
            </li>
          </ul>
          <ul>
            <li>
              Please send your inquiries concerning privacy issues in South
              Korea to our local representative pursuant to the Personal
              Information Protection Act. Contact details are as follows.
            </li>
          </ul>
          <p>Bae, Kim and Lee LLC</p>
          <p>Representative: Yangho Oh</p>
          <p>Address: Tower B, Centropolis, 26 Ujeongguk-ro</p>
          <p>Jongno-gu, Seoul 03161, Korea</p>
          <p>Phone: +82-2-3404-0108</p>
          <p>Email: privacyInstantAwesomKids@bkl.co.kr</p>
          <h3 className="fw-bold my-4">Türkiye</h3>
          <p>
            If you are using our services in Turkiye, the following additional
            terms apply along with the contents of the foregoing Policy. In the
            event of any conflict between the following additional terms and the
            provisions of the main body of this policy, the following terms
            shall prevail.
          </p>
          <p>
            Data Controller Representative. You may contact our data controller
            representative in Turkiye by email or mail to handle questions and
            complaints in connection with the processing of your personal data
            if you are in Turkiye.
          </p>
          <p>ESİN ATTORNEY PARTNERSHIP</p>
          <p>InstantAwesomeKids@esin.av.tr</p>
          <p>
            AKAT MAHALLESİ GÜL SOKAK NO: 2/1 34335 BEŞİKTAŞ/İSTANBUL, TÜRKİYE
          </p>
          <p>
            Methods of collection, processing purposes of, and legal bases for,
            processing your personal data. Please refer to our Privacy Policy
            (in Turkish) and its Addendum, for detailed information about
            personal data categories we process, our processing purposes and
            related legal bases for processing.
          </p>
          <p>
            Your rights. According to Article 11 of the Data Protection Law, you
            have the following rights with respect to your personal data:
          </p>
          <ul>
            <li>the right to learn whether we process your personal data,</li>
          </ul>
          <ul>
            <li>
              the right to request information with regard to such processing,
              if we process your personal data,
            </li>
          </ul>
          <ul>
            <li>
              the right to learn the purposes of the processing and whether they
              are used for such purpose or not;
            </li>
          </ul>
          <ul>
            <li>
              the right to know the third parties within or outside the country,
              to whom we transfer your personal data,
            </li>
          </ul>
          <ul>
            <li>
              the right to request correction of incomplete or inaccurate
              personal data,
            </li>
          </ul>
          <ul>
            <li>
              the right to request deletion or destruction of your personal data
              under the conditions set forth in Article 7 of the Data Protection
              Law,
            </li>
          </ul>
          <ul>
            <li>
              the right to request that we notify the third parties, to whom we
              transferred your personal data, about the correction, deletion
              and/or destruction of your personal data per your request within
              the scope of the foregoing two items,
            </li>
          </ul>
          <ul>
            <li>
              the right to object to the negative results about you that are due
              to the analysis of your personal data processed solely by
              automated means,
            </li>
          </ul>
          <ul>
            <li>
              the right to claim indemnification for damages incurred due to
              illegal processing of your personal data.
            </li>
          </ul>
          <p>
            You may exercise your rights listed above by contacting us at:
            https://www.InstantAwesomeKids.com/legal/report/privacy.
          </p>
          <p>
            The requests found in your application will be resolved as soon as
            possible according to the nature of your request and within thirty
            days at the latest free of charge. However, if your request incurs
            additional cost to our company, then you may be charged over the
            tariff fee determined by the Turkish Personal Data Protection Board.
          </p>
          <p>
            Cookies. Please refer to our Turkish Privacy Policy for further
            information on our use of cookies on our website.
          </p>
          <p>
            Amendments. The following sections of the Policy do not apply to
            individuals in Turkiye:
          </p>
          <p>
            The following sentence at the end of the second paragraph of the
            introduction section: If you do not agree with this policy, please
            do not use this Platform.
          </p>
          <p>
            Language. This policy is available in English and Turkish. If there
            is any inconsistency or different interpretation between the English
            and Turkish versions, the Turkish text shall prevail.
          </p>
          <h3 className="fw-bold my-4">United Arab Emirates</h3>
          <p>
            If you are using the Platform in the United Arab Emirates (“UAE”),
            the following additional terms apply. If any conflict arises between
            the main Privacy Policy and the additional terms, the following
            terms shall prevail:
          </p>
          <p>How we share your personal data</p>
          <p>
            In addition to the main text of this policy, by using the Platform
            in the UAE you agree to the terms of this Privacy Policy and consent
            to our sharing of your personal data in line with the main text of
            this Privacy Policy.
          </p>
          <p>Governing law and dispute resolution</p>
          <p>
            By using our Platform in the UAE, this Privacy Policy will be
            governed by and interpreted in accordance with the laws of the UAE.
            In the event of a dispute arising between the parties in connection
            with this Privacy Policy:
          </p>
          <ul>
            <li>
              where the dispute relates to a claim for a sum within the limits
              specified by the DIFC Small Claims Tribunal from time to time,
              then the dispute shall be referred by either party to the said
              Tribunal; and
            </li>
          </ul>
          <ul>
            <li>
              for all other disputes, the parties shall seek settlement of that
              dispute by mediation in accordance with the Mediation Rules of the
              DIFC-LCIA Arbitration Centre, which Rules are deemed to be
              incorporated by reference to this clause. If the dispute is not
              settled by mediation within 30 days of the commencement of the
              mediation, or such further period as the parties shall agree in
              writing, the dispute shall be referred to and finally resolved by
              arbitration under the Arbitration Rules of the DIFC-LCIA
              Arbitration Centre, which Rules are deemed to be incorporated by
              reference to this clause. The language to be used in the mediation
              and in the arbitration, shall be English. In any arbitration
              commenced pursuant to this clause the number of arbitrators shall
              be one and the arbitration shall be conducted in Dubai.
            </li>
          </ul>
          <h3 className="fw-bold my-4">Vietnam</h3>
          <p>
            If you are using our services in Vietnam, the following additional
            terms apply. In the event of any conflict between the following
            additional terms and the provisions of the main body of this policy,
            the following terms shall prevail.
          </p>
          <p>
            Age, Parental and Guardian Consent. By accessing and/or using this
            Platform, you represent that you are at least 16 years of age or not
            under guardianship. If you are below 16 years old or under
            guardianship:
          </p>
          <ul>
            <li>
              you must obtain approval from your parent(s) or legal guardian(s);
              and
            </li>
          </ul>
          <ul>
            <li>
              your parent(s) or legal guardian(s) are responsible for: (i) all
              your actions in connection with your access to and use of the
              Platform; (ii) your compliance with this policy; and (iii)
              ensuring that any of your participation in the Platform will not,
              in any event, result in any violation of applicable laws and
              regulations relating to child protections.
            </li>
          </ul>
          <p>
            If you do not have consent from your parent(s) or legal guardian(s)
            and your parent(s) or legal guardian(s) is not willing to open the
            account under their name, you must cease accessing the Platform.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
