import { useState } from "react";
import { useLoginWithEmailMutation } from "../../../services/authService";
import { RiEyeCloseLine } from "react-icons/ri";

const EmailLogin = ({
  handlePhone,
  handleSignup,
  onHide,
  resetPassword,
  resetScreen,
}) => {
  const [login] = useLoginWithEmailMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login({ ...data, usingPhone: false }).then((res) => {
      setLoading(false);
      if (!res.error) {
        onHide();
      }
    });
  };

  return (
    <div>
      <div className="emailLogin">
        <h3 className=" fw-bold fs-3 text-center mb-4">Log in </h3>

        <div className="px-5" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between mb-2">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              Email or username
            </label>
            <span
              onClick={handlePhone}
              className="phone_login_text cursor-pointer"
            >
              Log in with phone
            </span>
          </div>

          <form
            onSubmit={handleSubmit}
            className="login_form d-flex flex-column gap-2"
          >
            <div className="input_container input-group input-group-lg">
              <input
                value={data.email}
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Email or username"
              />
            </div>
            <div className="input_container input-group input-group-lg">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={data.password}
                name="password"
                onChange={handleChange}
              />
              <RiEyeCloseLine
                className="close_eye"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <span
              onClick={() => {
                resetScreen("email");
                resetPassword();
              }}
              className="phone_login_text cursor-pointer"
            >
              Forgot password?
            </span>
            <button
              disabled={isLoading || data.password === "" || data.email === ""}
              className="code mt-3 py-2 mb-5"
            >
              {isLoading ? (
                <div class="loadingio-spinner-rolling-x0a55a0iw5">
                  <div class="ldio-6g08kqzye5a">
                    <div></div>
                  </div>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>

        <hr />
        <div className="text-center">
          <span>Don't have an Account? </span>
          <span onClick={handleSignup} className="login_link cursor-pointer">
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmailLogin;
