import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import fb from "../../../assets/img/fb.png";
import google from "../../../assets/img/google.png";
import { useGoogleLogin } from "@react-oauth/google";
import {
  useAuthFbMutation,
  useAuthGoogleMutation,
} from "../../../services/authService";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const Login = ({ handlePhone, handleSignup, handleDob, onHide }) => {
  const [authGoogle] = useAuthGoogleMutation();
  const [authFb] = useAuthFbMutation();
  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      authGoogle({ code }).then(({ data }) => {
        if (!data.success || data.success === false) {
          handleDob(data.email);
        } else if (data.success) {
          onHide();
        }
      });
    },
    flow: "auth-code",
  });

  const responseFacebook = ({ email }) => {
    if (email) {
      authFb({ email }).then(({ data }) => {
        if (!data.success || data.success === false) {
          handleDob(email);
        } else if (data.success) {
          onHide();
        }
      });
    }
  };

  return (
    <div>
      <div className="login">
        <h3 className=" fw-bold fs-3 text-center">Log in</h3>

        <div
          className="d-flex flex-column align-items-center gap-3 py-4 px-5"
          style={{ width: "100%" }}
        >
          <div className="login_with py-2">
            <div className="auth_icon">
              <AiOutlineUser size={20} />
            </div>
            <p onClick={handlePhone} to="" className="">
              Use Phone / email / username
            </p>
          </div>
          <FacebookLogin
            appId="226980136925800"
            autoLoad={false}
            fields="email"
            callback={responseFacebook}
            render={(renderProps) => (
              <div
                onClick={() => renderProps.onClick()}
                className="login_with py-2"
              >
                <div className="auth_icon">
                  <img src={fb} alt="" />
                </div>
                <p to="" className="">
                  Continue with Facebook
                </p>
              </div>
            )}
          />

          <div className="login_with py-2" onClick={login}>
            <div className="auth_icon">
              <img src={google} alt="" />
            </div>
            <p to="" className="">
              Continue with Google
            </p>
          </div>
        </div>
        <hr />
        <div className="text-center">
          <span>Don't have as Account? </span>
          <span
            onClick={handleSignup}
            style={{ color: "#ec1c24" }}
            className="login_link cursor-pointer"
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
