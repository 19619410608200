import { Modal } from "react-bootstrap";
import React from "react";

const Discard = ({ show, onHide, discardChanges }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <div className="py-2 px-5">
        <h3 className="mt-3 mb-1 fw-bold text-center">Discard this post?</h3>
        <p className="m-1 mb-4 text-center">
          The video and all edits will be discarded.
        </p>

        <button
          onClick={discardChanges}
          className="upload_video_btn w-100 mb-3"
        >
          Discard
        </button>
        <button
          onClick={onHide}
          className="upload_video_btn outline w-100 mb-4"
        >
          Continue editing
        </button>
      </div>
    </Modal>
  );
};

export default Discard;
