import React, { useState } from "react";
import SignupEmail from "./SignupEmail";
import SignupPhone from "./SignupPhone";

const EmailPhone = ({ handleLogin, setScreen }) => {
  const [dob, setDob] = useState({ day: 0, month: 0, year: 0 });
  const [sPhone, setSPhone] = useState(true);
  const [sEmail, setSEmail] = useState(false);

  const signupEmail = () => {
    setSPhone(false);
    setSEmail(true);
  };
  const signupPhone = () => {
    setSPhone(true);
    setSEmail(false);
  };
  const renderDays = () => {
    const days = [];
    for (let day = 1; day <= 31; day++) {
      days.push(
        <li onClick={() => setDob({ ...dob, day })} key={day}>
          <p class="dropdown-item cursor-pointer m-0">{day}</p>
        </li>
      );
    }
    return days;
  };

  const renderYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100; // Adjust the starting year as needed
    const endYear = currentYear;

    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(
        <li onClick={() => setDob({ ...dob, year })} key={year}>
          <p class="dropdown-item cursor-pointer m-0">{year}</p>
        </li>
      );
    }
    return years;
  };

  const months = [
    { value: 1, name: "January" },
    { value: 2, name: "February" },
    { value: 3, name: "March" },
    { value: 4, name: "April" },
    { value: 5, name: "May" },
    { value: 6, name: "June" },
    { value: 7, name: "July" },
    { value: 8, name: "August" },
    { value: 9, name: "September" },
    { value: 10, name: "October" },
    { value: 11, name: "November" },
    { value: 12, name: "December" },
  ];

  return (
    <div>
      <div className="emailLogin">
        <h3 className=" fw-bold fs-3 text-center mb-4">Sign up </h3>

        <div className="px-5" style={{ width: "100%" }}>
          <div className="">
            <p>When's your birthday?</p>
            <div className="d-flex justify-content-between mb-2">
              <div class="dropdown">
                <button
                  class=" px-4 btn py-2 dropdown-toggle"
                  type="button"
                  style={{ backgroundColor: "rgba(22, 24, 35, 0.06)" }}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {dob.month
                    ? months.find((month) => month.value === dob.month).name
                    : "Month"}
                </button>
                <ul
                  class="dropdown-menu"
                  style={{
                    height: "250px",
                    background: "white",
                    overflow: "scroll",
                  }}
                  aria-labelledby="dropdownMenuButton1"
                >
                  {months?.map((month, i) => (
                    <li
                      onClick={() => setDob({ ...dob, month: month.value })}
                      key={i}
                    >
                      <p class="dropdown-item cursor-pointer m-0">
                        {month.name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>

              <div class="dropdown">
                <button
                  class="btn py-2 px-4 dropdown-toggle"
                  type="button"
                  style={{ backgroundColor: "rgba(22, 24, 35, 0.06)" }}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {dob.day || "Day"}
                </button>
                <ul
                  class="dropdown-menu"
                  style={{
                    height: "250px",
                    background: "white",
                    overflow: "scroll",
                  }}
                  aria-labelledby="dropdownMenuButton1"
                >
                  {renderDays()}
                </ul>
              </div>

              <div class="dropdown">
                <button
                  class="btn py-2 px-4 dropdown-toggle"
                  type="button"
                  style={{ backgroundColor: "rgba(22, 24, 35, 0.06)" }}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {dob.year || "Year"}
                </button>
                <ul
                  style={{
                    height: "250px",
                    background: "white",
                    overflow: "scroll",
                  }}
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {renderYears()}
                </ul>
              </div>
            </div>
          </div>
          {sPhone && (
            <SignupPhone
              setUsername={() => setScreen("usernamePassword")}
              dob={dob}
              signupEmail={signupEmail}
            />
          )}
          {sEmail && (
            <SignupEmail
              setUsername={() => setScreen("username")}
              dob={dob}
              signupPhone={signupPhone}
            />
          )}
        </div>

        <hr />
        <div className="text-center">
          <span>Already have an Account? </span>
          <span onClick={handleLogin} className="login_link cursor-pointer">
            Log in
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmailPhone;
