import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import Footer from "./Footer";

const Guidelines = () => {
  return (
    <>
      <div className="contact_logo py-2 privacy_nav">
        <div className="main_logo align-items-center justify-content-start my-2 ps-4">
          <Link to={"/"}>
            <img src={logo} alt="Instant Awesome Kids" />
          </Link>
        </div>
        <hr />
      </div>
      <div className="d-flex guideline">
        <div className="col-4 px-5 mt-5 privacy_left">
          <p className="side_text fw-bold"> Overview</p>
          <p className="side_text">Community Principles </p>
          <p className="side_text">Youth Safety and Well-Being </p>
          <p className="side_text">Safety and Civility</p>
          <p className="side_text">Mental and Behavioral Health</p>
          <p className="side_text"> Sensitive and Mature Themes</p>
          <p className="side_text">Integrity and Authenticity</p>
          <p className="side_text">
            Regulated Goods and Commercial <br /> Activities
          </p>
          <p className="side_text"> Privacy and Security</p>
          <p className="side_text"> For You feed Eligibility Standards</p>
          <p className="side_text"> Accounts and Features</p>
          <p className="side_text">Enforcement</p>
        </div>

        <div className="privacy_right col-8 pe-5">
          <h2 className="fw-bold my-5">Community Guidelines</h2>
          <h3 className="fw-bold mb-5">Overview</h3>
          <small className="text-muted mb-4">Last updated, March 2023</small>
          <p className="guideline_text mt-4">
            Our mission is to inspire creativity and bring joy. We aspire to
            unlock human imagination by enabling creative expression and being a
            source of entertainment and enrichment everywhere. We welcome people
            from around the world, as they come to Instant Awewsome Kids to
            discover a diversity of ideas, creators, and products, and to
            connect with others in our community. To help ensure a safe,
            trustworthy, and vibrant experience, we maintain a set of Community
            Guidelines that include rules and standards for using Instant
            Awewsome Kids. The guidelines apply to everyone and everything on
            our platform. They are informed by international legal frameworks,
            industry best practices, and input from our community, safety and
            public health experts, and our regional Advisory Councils. We evolve
            them to address emerging risks and potential harms that may occur
            from new behaviors.Our approach to content moderation is built on
            four pillars:
          </p>
          <p className="guideline_text">
            1. <span className="fw-bold">Remove violative content </span>
            from the platform that breaks our rules
          </p>
          <p className="guideline_text">
            2. <span className="fw-bold"> Age-restrict mature content</span> so
            it is only viewed by adults (18 years and older)
          </p>
          <p className="guideline_text">
            3.
            <span className="fw-bold text-center">
              Maintain For You feed (FYF) eligibility standards
            </span>
            to help ensure any content that may be promoted by our
            recommendation system is appropriate for a broad audience
          </p>
          <p className="guideline_text">
            4. <span className="fw-bold">Empower our community</span> with
            information, tools, and resources
          </p>
          <p className="guideline_text my-5">
            Our guidelines are organized by topic area, with each rule in bold.
            Under each section you can click for more information where you can
            find definitions, specific examples, and clarifications to common
            questions about what is allowed. Please be aware that the examples
            do not cover everything (we note this upfront so you don’t have to
            repeatedly read the phrase “including, but not limited to”).If you
            are ever in doubt about what to share, keep in mind this core value
            — “be kind and treat others the way that you would want to be
            treated.” Thanks for helping to keep Inastant Awesome Kids a
            welcoming space for everyone!
          </p>
          {/* accordian */}
          <div class="accordion" id="accordionExample">
            <div class="">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  More Information{" "}
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="guideline_text">
                    Keeping our community both safe and vibrant requires finding
                    the right balance between enabling expression and preventing
                    harm. We do this through a mix of safety approaches, from
                    developing rules and standards that we enforce, to building
                    platform norms and resources that give our community members
                    more agency and choice.
                  </p>
                  <p className="guideline_text">
                    1.
                    <span className="fw-bold"> Remove violative content.</span>
                    Everyone joins Inastant Awesome Kids with the ability to
                    freely share content on our platform. However, we have rules
                    regarding harmful content that we do not allow. We seek to
                    remove any content, whether publicly posted or private, that
                    violates our rules. Although we work hard to enforce our
                    rules, we cannot guarantee that all content shared on
                    Inastant Awesome Kids complies with our Terms of Service or
                    Community Guidelines. If someone has severe or repeated
                    violations, we will also ban the account. Information on
                    account-level enforcement can be found here.
                  </p>
                  <p className="guideline_text">
                    2.
                    <span className="fw-bold">
                      Age-restrict mature content.
                    </span>
                    The breadth of content that is shared on Inastant Awesome
                    Kids is vast, which adds to the rich diversity of the
                    experience. However, not all of it may be suitable for
                    younger audiences. We limit overtly mature content so it is
                    only viewed by adults 18 years and older. A summary of
                    age-restricted content can be found here.
                  </p>
                  <p className="guideline_text">
                    3.
                    <span className="fw-bold">
                      Maintain For You feed (FYF) eligibility standards.
                    </span>
                    The FYF offers an opportunity for viewers to discover new
                    content and for creators to reach new audiences. But
                    promotion by our recommendation system is not guaranteed.
                    Content that is not appropriate for a broad audience will be
                    ineligible for the FYF. A summary of these standards can be
                    found here.
                  </p>
                  <p className="guideline_text">
                    4.
                    <span className="fw-bold"> Empower our community. </span>
                    We want to make sure you have the right information to help
                    you manage your experience on Inastant Awesome Kids. For
                    certain content, we add labels, “opt-in” screens, or
                    warnings to provide more context or notice. Our safety
                    toolkit helps you to filter out any content with specific
                    hashtags or comments that you are not comfortable seeing. We
                    also offer account controls and in-app features with safety
                    resources.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* accordian */}

          <div className="d-flex justify-content-between align-items-center my-5">
            <h3 className="helpful fw-bold">Was it helpful?</h3>
            <div>
              <div
                className="yes btn px-3 me-3"
                style={{ backgroundColor: "#eee" }}
              >
                Yes
              </div>
              <div className="yes btn px-3" style={{ backgroundColor: "#eee" }}>
                No
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Guidelines;
