import SideBar from "../components/SideBar";
import { useState } from "react";
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";

const MainLayout = ({ children }) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <div className="d-flex p-3 overflow-hidden mainLayout" >
      <div className="sidebar_container">
        {/* <button onClick={toggleSidebar}>
          {isOpen ? <RxCross1 size={28} /> : <RxHamburgerMenu size={28} />}
        </button> */}

        <SideBar
        // isOpen={isOpen}
        />
      </div>
      <div className="w-100">{children}</div>
    </div>
  );
};

export default MainLayout;
