import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loader from "./utils/Loader";
import ComingSoon from "./pages/ComingSoon";
import Home from "./pages/Home";
import AuthContainer from "./components/auth/AuthContainer";
import AboutUs from "./pages/About/AboutUs";
import Support from "./components/Support";
import Contact from "./components/Contact";
import Safety from "./components/Safety";
import Guidelines from "./components/Guidelines";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Upload from "./pages/Upload";
import Profile from "./pages/Profile";
import Chat from "./pages/Chat";
import { useSelector } from "react-redux";
import ViewProfile from "./pages/ViewProfile";
import Video from "./pages/Video";
import UserDetail from "./pages/UserDetail";
import Search from "./pages/Search";

function Pages() {
  const { isAuthenticated } = useSelector((state) => state.authData);

  return (
    <>
      {isAuthenticated ? (
        <Switch>
          <Route exact path="/" component={ComingSoon} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/chat" component={Chat} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/safety" component={Safety} />
          <Route exact path="/community-guideline" component={Guidelines} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/upload" component={Upload} />
          <Route exact path="/profile" component={ViewProfile} />
          <Route exact path="/success" component={Home} />
          <Route exact path="/video/:id" component={Video} />
          <Route exact path="/userprofile/:userId" component={UserDetail} />
          <Route exact path="/search" component={Search} />
          <Redirect path="/*" to={"/"} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/" component={ComingSoon} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/userprofile/:userId" component={UserDetail} />
          <Route exact path="/video/:id" component={Video} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/safety" component={Safety} />
          <Route exact path="/community-guideline" component={Guidelines} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/success" component={Home} />
          <Redirect path="/*" to={"/"} />
        </Switch>
      )}

      <Loader />
    </>
  );
}

export default Pages;
