import React from "react";
import logo from "../assets/img/logo.png";
import speaker from "../assets/img/partner.png";
import media from "../assets/img/media.png";
import legal from "../assets/img/justice.png";
import lock from "../assets/img/privacy.png";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const Contact = () => {
  return (
    <>
      <div className="contact_logo py-2">
        <div className="main_logo align-items-center justify-content-start my-2 px-4">
          {/* <AiOutlineMenu size={30} className="mx-4" /> */}
          <Link to={"/"}>
            <img src={logo} alt="Instant Awesome Kids" />
          </Link>
        </div>
        <hr />
      </div>

      <div className="">
        <p className=" fs-1 fw-bold text-center py-5">Contact</p>
        <div className="inner_contact rounded container pt-4">
          <div className="text-center  py-4">
            <h2 style={{ fontWeight: "400" }}>Visit the Help Center</h2>
            <Link to={"/support"} className="link text-decoration-none">
              support.InstantAwesomeKids.com
            </Link>
          </div>
          <hr />
          <div className="contact_info d-flex  py-3 justify-content-between px-4 align-items-center">
            <div className="full-width d-flex align-items-center gap-3">
              <div>
                <img src={speaker} alt="" />
              </div>
              <div>
                <h6>Partners</h6>
                <p className="link">Advertise on Instant Awesome Kids</p>
              </div>
            </div>
            <div className="full-width d-flex align-items-center gap-3">
              <div>
                <img src={media} alt="" />
              </div>
              <div>
                <h6>Media Inquiries</h6>
                <p className="link">newsroom.InstantAwesomeKids.com</p>
              </div>
            </div>
          </div>
          <hr />

          <div className="contact_info d-flex py-3 justify-content-between px-4 align-items-center">
            <div className="full-width d-flex align-items-center gap-3">
              <div>
                <img src={legal} alt="" />
              </div>
              <div className="d-flex flex-column">
                <h6 className="">Legal Inquiries</h6>
                <strong className="link">Law Enforcement Guidelines</strong>
                <strong className="link"> Intellectual Property</strong>
              </div>
            </div>
            <div
              className="full-width d-flex align-items-center gap-3"
              style={{ width: "21.4rem" }}
            >
              <div>
                <img src={lock} alt="" />
              </div>
              <div>
                <h6>Privacy</h6>
                <p className="link"> Learn more</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
