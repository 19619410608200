import React, { useRef, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import Navbar from "../components/Navbar";
import { FaPause, FaPlay } from "react-icons/fa";
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { BsFillHeartFill } from "react-icons/bs";
import { FaCommentDots } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import coin from "../assets/img/coin.png";
import profile from "../assets/img/profile.png";
import { MutatingDots } from "react-loader-spinner";
import { BsLink45Deg } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import {
  FaWhatsapp,
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedinIn,
} from "react-icons/fa";
import {
  useAddCommentMutation,
  useFollowUserMutation,
  useGetVideoQuery,
  useLikeVideoMutation,
  useShareVideoMutation,
} from "../services/videoService";
import CommentBox from "../components/CommentBox";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../redux/slices/globalSlice";
import moment from "moment";
import { useEffect } from "react";
import { MdJoinFull } from "react-icons/md";
import { toast } from "react-toastify";

const Video = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.authData);

  const [followUser] = useFollowUserMutation();
  const [likeVideo] = useLikeVideoMutation();
  const [shareVideo] = useShareVideoMutation();

  const { data, refetch } = useGetVideoQuery(
    { id, user: user?.id },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const playerRef = useRef();

  const [comments, setComments] = useState(data?.comments || []);
  const [addComment] = useAddCommentMutation();
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [isLiked, setIsLiked] = useState(data?.isLiked);
  const [isFollowed, setIsFollowed] = useState(data?.isFollowed);
  const [likes, setLikes] = useState(data?.likes || 0);
  const [shares, setShares] = useState(data?.video?.shares || 0);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (data) {
      setComments(data.comments);
      setIsLiked(data.isLiked);
      setIsFollowed(data.isFollowed);
      setLikes(data.likes);
      setShares(data.video.shares || 0);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [isAuthenticated]);

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const handleClick = (e) => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleMouseEnter = () => {
    setShowBox(true);
  };
  const handleMouseLeave = () => {
    setShowBox(false);
  };

  const emojis = [
    "👍",
    "🔥",
    "😄",
    "😁",
    "🙂",
    "😃",
    "😅",
    "😂",
    "🤣",
    "😇",
    "😊",
    "🥰",
    "😍",
    "🤗",
    "😮",
    "⭐",
    "💕",
  ];

  const addNewComment = (comment) => {
    if (!isAuthenticated) {
      dispatch(setModal(true));
    } else {
      addComment({ comment, video: data?.video.id }).then((res) => {
        if (!res.error) {
          const newComments = [
            ...comments,
            { video: data?.video.id, user, comment, createdAt: moment() },
          ];
          setComments(
            newComments.sort(
              (a, b) => moment(b.createdAt) - moment(a.createdAt)
            )
          );
        }
      });
    }
  };

  const handleLike = () => {
    if (!isAuthenticated) {
      dispatch(setModal(true));
    } else {
      likeVideo({ video: data?.video?.id }).then((res) => {
        if (!res.error) {
          setIsLiked(!isLiked);
          setLikes(isLiked ? likes - 1 : likes + 1);
        }
      });
    }
  };

  const handleFollower = () => {
    if (!isAuthenticated) {
      dispatch(setModal(true));
    } else {
      followUser({ user: data?.video.user.id }).then((res) => {
        if (!res.error) {
          setIsFollowed(!isFollowed);
        }
      });
    }
  };

  const handleShare = () => {
    shareVideo(data?.video.id).then((res) => {
      if (!res.error) {
        setShares(shares + 1);
      }
    });
  };

  const copyLink = () => {
    handleShare();
    navigator.clipboard.writeText(
      `${window.location.origin}/video/${data?.video?.id}`
    );
    toast.success("Link copied");
  };

  return (
    <MainLayout>
      <Navbar />
      {data && (
        <div>
          <div className="row main_video d-flex justify-content-between m-auto">
            <div className="left col-6 m-xl-5">
              <div className="d-flex mb-3 gap-3 align-items-center">
                <div>
                  <img
                    className="avatar video-card-avatar"
                    src={data?.video?.user?.profile}
                    crossOrigin="anonymous"
                    alt=""
                  />
                </div>
                <div className="profile_details">
                  <p className="d-flex align-items-center m-0">
                    <span className="fw-bold me-3">
                      {data?.video?.user?.username}
                    </span>
                    {/* Shredders */}
                  </p>
                  {/* <span>Episode | best oddly satisfying </span> */}
                </div>
              </div>

              <div className="mb-2">
                <span className="cardSpan">{data?.video?.caption}</span>
              </div>
              <div className="video-player">
                <div className="video-loader">
                  <MutatingDots
                    height="100"
                    width="100"
                    color="#ee353b"
                    secondaryColor="aquamarine"
                    radius="12.5"
                    visible={isLoading}
                    ariaLabel="loading"
                    // wrapperStyle
                    // wrapperClass
                  />
                </div>
                <video
                  ref={playerRef}
                  loop
                  playsInline
                  muted={isMuted}
                  onLoadedData={() => setIsLoading(false)}
                  onWaiting={() => setIsLoading(true)}
                  onPlaying={() => setIsLoading(false)}
                  className="cursor-pointer"
                >
                  <source
                    src={
                      "https://iak-media.nyc3.digitaloceanspaces.com/" +
                      data?.video?.video
                    }
                    type="video/mp4"
                  />
                </video>
                <div className="d-flex justify-content-between video-controls align-items-center">
                  {isPlaying ? (
                    <FaPause
                      onClick={handleClick}
                      size={20}
                      className="cursor-pointer"
                    />
                  ) : (
                    <FaPlay
                      onClick={handleClick}
                      size={20}
                      className="cursor-pointer"
                    />
                  )}
                  {isMuted ? (
                    <HiOutlineVolumeOff
                      onClick={handleMute}
                      className="cursor-pointer"
                      size={25}
                    />
                  ) : (
                    <HiOutlineVolumeUp
                      onClick={handleMute}
                      className="cursor-pointer"
                      size={25}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="right col-2 mt-5">
              <div className="mb-4">
                {data?.video?.user.id !== user?.id && (
                  <button
                    onClick={handleFollower}
                    className={`btn py-2 px-4 ${
                      isFollowed
                        ? "btn-outline-primary text-bold"
                        : "btn-primary"
                    }`}
                  >
                    {isFollowed ? "Following" : "Follow"}
                  </button>
                )}
              </div>
              <div className="d-flex gap-2 flex-column align-items-center mb-4">
                <div
                  className={`actions_btn ${isLiked && "active"}`}
                  onClick={handleLike}
                >
                  <BsFillHeartFill size={25} />
                </div>
                <span className="followers">{likes}</span>
              </div>{" "}
              <div className="d-flex gap-2 flex-column align-items-center mb-4">
                <div className="actions_btn">
                  <FaCommentDots size={25} />
                </div>
                <span className="followers">{comments.length}</span>
              </div>
              <div
                className="d-flex gap-2 flex-column align-items-center mb-4 position-relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="actions_btn">
                  <BsShareFill size={25} />
                </div>
                <span className="followers">{shares}</span>
                {showBox && (
                  <div
                    className="d-flex flex-column  shareBox shadow px-2 py-3"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex gap-3 shareLinks" onClick={copyLink}>
                      <div className="video-profile-controlsMedia bg-primary text-light ">
                        <BsLink45Deg size={18} />
                      </div>
                      <span>Copy Link</span>
                    </div>
                    <div className="d-flex gap-3 shareLinks">
                      <div
                        className="video-profile-controlsMedia text-light"
                        style={{ background: "#59CE72" }}
                      >
                        <FaWhatsapp size={17} />
                      </div>
                      <span>Share To WhatsApp</span>
                    </div>
                    <div className="d-flex gap-3 shareLinks">
                      <div
                        className="video-profile-controlsMedia text-light"
                        style={{ background: "#1877F2" }}
                      >
                        <FaFacebookF size={17} />
                      </div>
                      <span>Share To Facebook</span>
                    </div>
                    <div className="d-flex gap-3 shareLinks">
                      <div className="video-profile-controlsMedia bg-info text-light ">
                        <FaTwitter size={17} />
                      </div>
                      <span>Share To Twitter</span>
                    </div>
                    {!showMoreOptions && (
                      <div
                        onClick={toggleMoreOptions}
                        className="text-center shareLinks p-0"
                      >
                        <FaChevronDown size={20} />
                      </div>
                    )}

                    {showMoreOptions && (
                      <div>
                        <div className="d-flex gap-3 shareLinks">
                          <div className="video-profile-controlsMedia bg-info text-light ">
                            <FaTelegramPlane size={17} />
                          </div>
                          <span>Share To Telegram</span>
                        </div>

                        <div className="d-flex gap-3 shareLinks">
                          <div className="video-profile-controlsMedia bg-danger text-light ">
                            <FaPinterestP size={17} />
                          </div>
                          <span>Share To Pinterest</span>
                        </div>

                        <div className="d-flex gap-3 shareLinks">
                          <div
                            className="video-profile-controlsMedia text-light"
                            style={{ background: "#1877F2" }}
                          >
                            <FaLinkedinIn size={17} />
                          </div>
                          <span>Share To LinkedIn</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="d-flex gap-2 flex-column align-items-center mb-4">
                <div className="actions_btn">
                  <img src={coin} />
                </div>
                <span className="followers">IAK Coin</span>
              </div>
            </div>
          </div>
          <div className="comments-section col-8 m-auto">
            <div className="d-flex video-comments flex-wrap">
              {emojis.map((emoji, i) => (
                <span onClick={() => addNewComment(emoji)} key={i}>
                  {emoji}
                </span>
              ))}
            </div>
            <div className="mt-2">
              {comments?.map((comment, i) => (
                <CommentBox key={i} comment={comment} />
              ))}
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default Video;
