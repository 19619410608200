import React, { useRef, useState } from "react";
import logo from "../assets/img/logo.png";
import user from "../assets/img/user.png";

import {
  AiOutlineCheckCircle,
  AiOutlinePlus,
  AiOutlineSearch,
} from "react-icons/ai";
import { RxPaperPlane } from "react-icons/rx";
import { BiMessageAltMinus } from "react-icons/bi";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { useAddVideoMutation } from "../services/videoService";
import { Link } from "react-router-dom";
import Discard from "../components/Modals/Discard";
import { FileUploader } from "react-drag-drop-files";

const Upload = () => {
  const [addVideo] = useAddVideoMutation();
  const fileInputRef = useRef();
  const [allowComments, setAllowComments] = useState(true);
  const [caption, setCaption] = useState("");
  const [privacy, setPrivacy] = useState(0);
  const [discardModal, setDiscardModal] = useState(false);
  const [file, setFile] = useState(null);

  const handleImageChange = (e) => {
    const mediaFile = e.target.files[0];
    setFile(mediaFile);
  };

  const discardChanges = () => {
    setFile(null);
    setDiscardModal(false);
    document.getElementById("fileInput").value = "";
  };

  const handleUpload = () => {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("privacy", privacy);
    fd.append("caption", caption);
    fd.append("allowComments", allowComments);
    addVideo(fd).then((res) => {
      if (!res.error) {
        setFile(null);
        setDiscardModal(false);
        document.getElementById("fileInput").value = "";
      }
    });
  };

  return (
    <>
      {discardModal && (
        <Discard
          show={discardModal}
          onHide={() => setDiscardModal(false)}
          discardChanges={discardChanges}
        />
      )}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <Link to="/home">
            <div className="main_logo">
              <img src={logo} alt="Instant Awesome Kids" />
            </div>
          </Link>
          <div className=" d-flex flex-grow-1 justify-content-center align-items-center">
            <div
              className="search_input upload_input"
              style={{ backgroundColor: "rgba(0,0,0,0.04)" }}
            >
              <input
                type="text"
                placeholder="Search accounts and videos"
                style={{ width: "250px" }}
              />
              <span className="line mx-2"></span>
              <AiOutlineSearch
                className="cursor-pointer"
                style={{ color: "rgba(0,0,0,0.4)", fontSize: "20px" }}
              />
            </div>
          </div>
          <div className="d-flex gap-3 align-items-center">
            <AiOutlineSearch
              size={44}
              className="cursor-pointer p-2 "
              style={{
                color: "rgba(0,0,0,0.4)",
                fontSize: "20px",
                backgroundColor: "rgba(0,0,0,0.04)",
                borderRadius: "50%",
              }}
            />
            <button className="btn upload_btn fw-bold py-2">
              <AiOutlinePlus className="me-1" fontWeight="bold" size={19} />
              Upload
            </button>
            <RxPaperPlane className="cursor-pointer" size={24} />
            <BiMessageAltMinus className="cursor-pointer" size={24} />
            <span className="circle-nm ">
              <img src={user} alt="Preview" />
            </span>
          </div>
        </div>
      </nav>
      <input
        type="file"
        ref={fileInputRef}
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleImageChange}
        accept="video/*"
      />
      <FileUploader
        handleChange={setFile}
        accept="video/*"
        classes="container upload_container d-block"
        maxSize={100}
        disabled={file ? true : false}
        fileOrFiles={file}
      >
        <div className="border_box p-2">
          <div className="upload_text col-md-6 col-sm-10">
            {!file ? (
              <>
                <BsFillCloudArrowUpFill size={40} color="rgba(0, 0, 0, 0.3)" />
                <h5 className="mt-3 mb-1">Select video to upload</h5>
                <p className="m-1">or drag and drop a file</p>
                <p className="text-center">
                  Long videos can be split into multiple parts to get more
                  exposure
                </p>
                <p className="m-1">MP4 or WebM</p>
                <p className="m-1">720*1280 resolution or higher</p>
                <p className="m-1">Up to 30 minute</p>
                <p>Less than 2 GB</p>
                <button
                  className="btn text-light w-100"
                  style={{ backgroundColor: "#ec1c24" }}
                >
                  Select file
                </button>
              </>
            ) : (
              <>
                <h3 className="mt-3 mb-1 fw-bold">Upload video</h3>
                <p className="m-1 mb-4">Post a video to your account</p>
                <div className="video-content-box d-flex align-items-center mb-3">
                  <div className="d-flex align-items-center">
                    <AiOutlineCheckCircle />
                    <span className="ms-2" style={{ overflowWrap: "anywhere" }}>
                      {file?.name}
                    </span>
                  </div>
                  <p
                    onClick={() => fileInputRef.current.click()}
                    className="ms-5"
                  >
                    Change video
                  </p>
                </div>
                <div className="input-group input-group-lg video_container">
                  <label htmlFor="caption">Caption</label>
                  <input
                    value={caption}
                    type="text"
                    onChange={(e) => setCaption(e.target.value)}
                    id="caption"
                  />
                </div>
                <div className="input-group input-group-lg video_container">
                  <label htmlFor="caption">Who can watch this video</label>
                  <select onChange={(e) => setPrivacy(e.target.value)}>
                    <option selected value={0}>
                      Public
                    </option>
                    <option value={1}>Friends</option>
                    <option value={2}>Private</option>
                  </select>
                </div>
                <div className="input-group input-group-lg video_container d-flex align-items-center flex-row">
                  <input
                    type="checkbox"
                    id="checkbox"
                    className="checkbox"
                    checked={allowComments}
                    onChange={() => setAllowComments(!allowComments)}
                  />{" "}
                  <label htmlFor="checkbox">Allow users to comment</label>
                </div>

                <div className="mt-4 w-100 d-flex mb-3">
                  <button
                    onClick={() => setDiscardModal(true)}
                    className="upload_video_btn outline me-3"
                  >
                    Discard
                  </button>
                  <button onClick={handleUpload} className="upload_video_btn">
                    Post
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </FileUploader>
    </>
  );
};

export default Upload;
