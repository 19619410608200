import React, { useState } from "react";
import { RiEyeCloseLine } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import {
  useResetWithEmailMutation,
  useResetWithPhoneMutation,
  useSendMailOTPMutation,
  useSendOTPMutation,
} from "../../../services/authService";

const ResetPassword = ({
  handleSignup,
  resetScreen,
  setResetScreen,
  onHide,
}) => {
  const [sendOTP] = useSendOTPMutation();
  const [sendMailOTP] = useSendMailOTPMutation();
  const [resetWithPhone] = useResetWithPhoneMutation();
  const [resetWithEmail] = useResetWithEmailMutation();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [isValid, setValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [sending, setIsSending] = useState(false);

  const setMobile = (phone, data) => {
    setPhone(phone);
    setIsDisabled(phone.replace(data.dialCode, "")?.length !== 10);
    setValid(phone.replace(data.dialCode, "")?.length === 10);
  };
  const startTimer = (t) => {
    setSeconds(t);
    let s = t;
    var interval = setInterval(() => {
      s -= 1;
      setSeconds(s);
      if (s <= 0) {
        setSent(false);
        setIsDisabled(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleOTP = (e) => {
    e.preventDefault();
    if (sending) {
      return;
    }
    setIsSending(true);
    if (resetScreen === "phone") {
      sendOTP({ phone, usingPhone: true }).then((res) => {
        setIsSending(false);
        if (!res.error) {
          setSent(true);
          setIsDisabled(true);
          startTimer(60);
        }
      });
    } else {
      sendMailOTP({ email, usingPhone: false }).then((res) => {
        setIsSending(false);
        if (!res.error) {
          setSent(true);
          setIsDisabled(true);
          startTimer(60);
        }
      });
    }
  };

  const changeOTP = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setOTP(numericValue.length < 7 ? numericValue : otp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (resetScreen === "phone") {
      resetWithPhone({ phone, otp, password, usingPhone: true }).then((res) => {
        setLoading(false);
        if (!res.error) {
          onHide();
        }
      });
    } else {
      resetWithEmail({ email, otp, password, usingPhone: false }).then(
        (res) => {
          setLoading(false);
          if (!res.error) {
            onHide();
          }
        }
      );
    }
  };
  return (
    <div>
      <div className="phoneLogin">
        <h3 className="fw-bold fs-3 text-center mb-4">Reset password</h3>
        <div className="px-5" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between mb-2">
            <label style={{ fontSize: "14px", fontWeight: "500" }}>
              {resetScreen === "phone"
                ? "Enter phone number"
                : "Enter email address"}
            </label>
            {resetScreen === "phone" && (
              <span
                onClick={() => setResetScreen("email")}
                className="phone_login_text cursor-pointer"
              >
                Reset with email
              </span>
            )}
            {resetScreen === "email" && (
              <span
                onClick={() => setResetScreen("phone")}
                className="phone_login_text cursor-pointer"
              >
                Reset with phone number
              </span>
            )}
          </div>

          <form
            onSubmit={handleSubmit}
            className=" login_form d-flex flex-column gap-2"
          >
            {resetScreen === "phone" ? (
              <div class="input_container input-group input-group-lg">
                <PhoneInput
                  country={"us"}
                  inputClass="input_container w-100 h-100"
                  value={phone}
                  onChange={setMobile}
                  enableSearch={true}
                />
              </div>
            ) : (
              <div className="input_container input-group input-group-lg">
                <input
                  value={email}
                  type="text"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email address"
                />
              </div>
            )}

            <div class="input_container input-group input-group-lg">
              <input
                type="text"
                className=" form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                placeholder="Enter 6-digit code"
                value={otp}
                onChange={changeOTP}
              />
              <button
                onClick={handleOTP}
                className={`code input-group-text`}
                id="inputGroup-sizing-lg"
                disabled={isDisabled}
              >
                {sending ? (
                  <div class="loadingio-spinner-rolling-x0a55a0iw5">
                    <div class="ldio-6g08kqzye5a">
                      <div></div>
                    </div>
                  </div>
                ) : sent ? (
                  `Resend in ${seconds}s`
                ) : (
                  "Send code"
                )}
              </button>
            </div>
            <div className="input_container input-group input-group-lg">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <RiEyeCloseLine
                className="close_eye"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <button
              disabled={
                otp.length !== 6 ||
                (resetScreen === "phone" && !isValid) ||
                isLoading ||
                password === "" ||
                (resetScreen === "email" && email === "")
              }
              className="code mt-3 py-2 mb-5"
            >
              {isLoading ? (
                <div class="loadingio-spinner-rolling-x0a55a0iw5">
                  <div class="ldio-6g08kqzye5a">
                    <div></div>
                  </div>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>

        <hr />
        <div className="text-center">
          <span>Don't have an Account? </span>
          <span
            onClick={handleSignup}
            style={{ color: "#ec1c24" }}
            className="login_link cursor-pointer"
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
