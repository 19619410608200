import logo from "../assets/img/logo.png";
import { FaTv, FaUser } from "react-icons/fa";
import { RiHome6Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SideBar = () => {
  const { isAuthenticated } = useSelector((state) => state.authData);

  return (
    <>
      {/* <div className={`resposive_sidebar   ${isOpen ? "open" : ""}`}> */}
      <div className="sidebar_box p-3">
        <div className="main_logo my-2">
          <Link to={"/home"}>
            <img src={logo} alt="Instant Awesome Kids" />
          </Link>
        </div>
        <div className="my-3">
          <div className="d-flex justify-content-between tab py-2 cursor-pointer active">
            <div className="left_icon d-flex align-items-center">
              <RiHome6Fill fill="#ec1c24" size={24} />
              <span>For you</span>
            </div>
            <div className="active_bar bg-primary"></div>
          </div>
          <div className="d-flex justify-content-between tab py-2 cursor-pointer">
            <div className="left_icon d-flex align-items-center">
              <FaUser size={24} className="icon" />
              <span>Following</span>
            </div>
            <div className="active_bar bg-primary"></div>
          </div>
        </div>
        {!isAuthenticated && (
          <div className="login_text_box p-4">
            <p className="mb-4 light-text">
              Log in to follow creators, like videos, and view comments.
            </p>
            <button className="btn btn-primary w-100 py-2">Log in</button>
          </div>
        )}
        <div className="mt-4">
          <h6 className="sub_heading">Suggested Accounts:</h6>
          {/* <div className="account d-flex align-items-center">
            <div className="profile-img">
              <img src={profile} alt="" />
            </div>
            <div className="profile-info ms-3">
              <h5 className="m-0">Donna</h5>
              <p className="m-0">Donna Erdman</p>
            </div>
          </div> */}
          <div className="more_btn mt-2">
            <div className="p-2">
              <button className="btn btn-primary py-2 px-3">View More</button>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar_box p-3 mt-3">
        <h5 className="mb-4">Discover:</h5>
        <li className="list-unstyled mb-4"># instant awesome pets</li>
        <li className="list-unstyled mb-4"># instant awesome kids dance</li>
        <li className="list-unstyled mb-4"># instant awesome kids music</li>
        <li className="list-unstyled mb-4"># instant awesome kids athletics</li>
        <li className="list-unstyled mb-4"># instant awesome kids singing</li>
      </div>

      <div className="sidebar_box p-3 mt-3">
        <div className="">
          <div className="d-flex gap-1 mb-4 flex-wrap">
            <div className="lists">
              <span> Profile</span>
              <span className="dot"></span>
            </div>{" "}
            <div className="lists">
              <Link to={"/about"}>
                <span> About</span>
              </Link>
              <span className="dot"></span>
            </div>
            {/* <div className="lists">
              <span> Newsroom</span>
              <span className="dot"></span>
            </div>{" "} */}
            <div className="lists">
              <Link to="/contact">
                <span>Contact</span>
              </Link>
              <span className="dot"></span>
            </div>
            {/* <div className="lists">
              <span>Advertise</span>
              <span className="dot"></span>
            </div> */}
            {/* <div className="lists">
              <span>Rewards</span>
              <span className="dot"></span>
            </div> */}
            <div className="lists">
              <Link to="/support">
                <span>Help</span>
              </Link>
              <span className="dot"></span>
            </div>
            <div className="lists">
              <Link to="/safety">
                <span>Safety</span>
              </Link>
              <span className="dot"></span>
            </div>{" "}
            {/* <div className="lists">
              <Link>
                <span>Terms</span>
              </Link>
              <span className="dot"></span>
            </div> */}
            <div className="lists">
              <Link to="/privacy-policy">
                <span>Privacy</span>
              </Link>
              <span className="dot"></span>
            </div>
            {/* <div className="lists">
              <span>CreatorPortal</span>
              <span className="dot"></span>
            </div> */}
            <div className="lists">
              <Link to="/community-guidline">
                <span>Community Guideline</span>
              </Link>
            </div>
          </div>
        </div>

        <p className="" style={{ color: "rgba(0, 0, 0, 0.3)" }}>
          © 2023 Instant Awesome Kids
        </p>
      </div>
      {/* </div> */}
    </>
  );
};

export default SideBar;
